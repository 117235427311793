import Vue from "vue";
import Vuex from "vuex";

import userStore from './user';
import reminderStore from './reminders';

// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
const fac = localStorage.getItem('active_facility') || 'ohana';

export const store = new Vuex.Store({
  modules: {
   userStore,
   reminderStore
  },
  // plugins: [createPersistedState()],
  state: {
    facility: fac
  },
  getters: {
    FACILITY(state){
      return state.facility
    }
  },
  mutations: {
    set_facility(state, v){
      state.facility = v;
    }
  },
  actions: {
    facility_set({commit}, payload){
      localStorage.setItem('active_facility', payload)
      commit('set_facility', payload);
    }
    
  }
});
