import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
      themes: {
        dark: {
          primary: '#4db7c9',
          secondary: '#4db7c9',
          accent: '#4db7c9',
          error: '#b71c1c',
        },
      },
    },
  }

export default new Vuetify(opts)