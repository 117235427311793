<template>
    <div class="inner-stage"
        style="background: #f8fafb; display:flex; justify-content: center; padding-bottom:700px; min-height:100vh;">
        <div v-if="isLoading"><v-progress-circular color="#eaeaea" indeterminate :size="50"
                :width="5"></v-progress-circular></div>
        <div v-if="!isLoading" class="form-container" style="max-width:800px !important;">
            <div class="f-name" style="display:flex;">
                <i style="transform:translateY(8px)" class="fad fa-file"></i>
                <div style="width:100%; position:relative;">
                    <input type="text" class="form-name" v-model="config.name"><br><input type="text"
                        placeholder="Description" class="form-desc" v-model="config.instructions">
                </div>
            </div>
            <div class="f-form">
                <div v-for="(section, s) in config.layout" :key="s" class="f-section">
                    <div class="s-title" style="display:flex;">
                        <input class="s-title-input" type="text" placeholder="Unlabeled" v-model="section.section_name">
                        <i v-if="config.layout.length > 1" @click="deleteSection(s)"
                            class="trash-section fal fa-trash"></i>
                        <i class="trash-section fal fa-chevron-down" :class="{rotated:section.expanded}" style="margin-left:10px;" @click="section.expanded = !section.expanded"></i>
                    </div>
                    <v-expand-transition>
                    <div v-if="section.expanded" class="s-field">
                        <div v-if="section.alternative_text != null" v-html="section.alternative_text"></div>
                        <div class="element-row" v-for="(row, r) in section.rows" :key="r">
                            <div class="element-item" v-for="(prop, p) in row.props" :key="p">
                                <div class="element-desc">
                          
                                    <div v-if="config.fields[config.fields.findIndex((item) => item.prop == prop)] && config.fields[config.fields.findIndex((item) => item.prop == prop)].type" style="display:flex; align-items: center;"><i class="fad "
                                            :class="getIconClass(config.fields[config.fields.findIndex((item) => item.prop == prop)].type)"></i>
                                        &nbsp;{{ config.fields[config.fields.findIndex((item) => item.prop == prop)].label
                                        }}</div>
                                    <span
                                        v-if="config.fields[config.fields.findIndex((item) => item.prop == prop)] && config.fields[config.fields.findIndex((item) => item.prop == prop)].alternative_text != ''">{{
                                            config.fields[config.fields.findIndex((item) =>item.prop ==
                                        prop)].alternative_text }}</span>
                                    <span
                                        v-if="config.fields[config.fields.findIndex((item) => item.prop == prop)] && config.fields[config.fields.findIndex((item) => item.prop == prop)].options && config.fields[config.fields.findIndex((item) => item.prop == prop)].options.length"
                                        style="display:flex; flex-wrap:wrap; align-items: center; gap:5px"><span
                                            class="option-chip"
                                            v-for="(option, o) in config.fields[config.fields.findIndex((item) => item.prop == prop)].options"
                                            :key="o"><span style="font-size:1em" v-if="JSON.stringify(option).indexOf('{') > -1">{{ JSON.parse(JSON.stringify(option)).text }}</span><span v-else>{{ option }}</span></span></span>
                                    <span v-if="config.fields[config.fields.findIndex((item)=>item.prop == prop)] && availableElementTypes.filter((item) => item.value == config.fields[config.fields.findIndex((item)=>item.prop == prop)].type).length > 0" class="type-tag"
                                        :class="`e-${config.fields[config.fields.findIndex((item) => item.prop == prop)].type}`">{{
                                            availableElementTypes.filter((item) => item.value ==
                                                config.fields[config.fields.findIndex((item)=>item.prop == prop)].type)[0].text
                                        }}</span>
                                </div>
                                <div style="margin-left:auto; display:flex; align-items: center; gap:10px; position:relative;">
                                    <i @click="setAddElement(JSON.parse(JSON.stringify(config.fields[config.fields.findIndex((item)=>item.prop == prop)]))),  config.fields[config.fields.findIndex((item)=>item.prop == prop)].addElement = true" class="fal fa-pencil"></i>
                                    <i @click="removeField(s, r, p)" class="fal fa-trash"></i>

                                    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
                            <div v-click-outside="{ handler: () => config.fields[config.fields.findIndex((item)=>item.prop == prop)].addElement = false, include: includeFunction }"
                                v-if="config.fields[config.fields.findIndex((item)=>item.prop == prop)] && config.fields[config.fields.findIndex((item)=>item.prop == prop)].addElement" style="position: absolute; background:#fff; padding:15px; box-shadow:3px 3px 10px rgba(0,0,0,0.3); top:0; width:500px; right:0; z-index: 1000;" class="d-dropdown animated-fast">
                                <div>Edit Element</div>
                                <v-select :items="availableElementTypes" :color="appColor" x-small dense
                                    label="Element Type" outlined v-model="addElement.type"></v-select>
                                <v-form @submit.prevent="submitEditElement(s, r, p, prop)" ref="form" class="editElement" v-model="valid"
                                    lazy-validation>
                                    <!-- DATE OPTIONS -->
                                    <div v-if="addElement.type == 'date'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = section.name.replaceAll('-', '').replaceAll(' ', '-') + '-'+ $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TIME OPTIONS -->
                                    <div v-if="addElement.type == 'time'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- STRING OPTIONS -->
                                    <div v-if="addElement.type == 'string'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TEXTAREA OPTIONS -->
                                    <div v-if="addElement.type == 'textarea'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- NUMBER OPTIONS -->
                                    <div v-if="addElement.type == 'number'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- CHECKBOX OPTIONS -->
                                    <div v-if="addElement.type == 'boolean'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>

                                    <!-- CHECKBOX W/ COMMENT OPTIONS -->
                                    <div v-if="addElement.type == 'boolean_w_comment'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>

                                    <!-- SELECT OPTIONS -->
                                    <div v-if="addElement.type == 'select'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div
                                                style="display:flex; max-width:300px; align-items: center; flex-wrap: wrap; gap:5px; margin-top:15px;">
                                                <v-chip v-for="(option, o) in addElement.options" :key="o" dense small
                                                    v-bind="attrs" :input-value="selected" close @click="select"
                                                    @click:close="addElement.options.splice(addElement.options.indexOf(option), 1)">
                                                    <strong v-if="JSON.stringify(option).indexOf('{') > -1">{{ JSON.parse(JSON.stringify(option)).text }}</strong>
                                                    <strong v-else>{{ option }}</strong>

                                                </v-chip></div>
                                            <div style="display:flex; align-items:center; gap:10px;" class="e-value">
                                                <!-- <v-combobox v-model="addElement.options" style="max-width:300px" chips
                                                    :color="appColor" label="Select Options" multiple outlined dense>
                                                    <template v-slot:selection="{}">
                                                        <div style="width:0;"></div>
                                                    </template>
                                                </v-combobox> -->
                                                <v-text-field  @keydown.enter.prevent.stop chip hide-details :color="appColor" block v-model="addElement.tempOption.text" outlined label="Option Label" dense />
                                                <v-text-field @keydown.enter.prevent.stop chip hide-details :color="appColor" block v-model="addElement.tempOption.value" outlined label="Option Value" dense />
                                                <v-btn style="margin-top:22px;" hide-details dark :color="appColor" depressed small @click="addElement.options.push({text:addElement.tempOption.text, value:addElement.tempOption.value}), addElement.tempOption.text = '', addElement.tempOption.value = ''">Add Option</v-btn>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>


                                    <div style="font-size:.8em; color:#ff0000" v-if="addElement.errorMessage">{{ addElement.errorMessage }}</div>
                                    <v-btn type="submit" x-small dark :color="appColor" depressed>Save</v-btn>
                                </v-form>
                            </div>
                        </transition>
                                </div>

                            </div>


                        </div>
                    </div>
                    </v-expand-transition>
                    <div class="add-form-element">
                       
                        <i @click="section.dropdown = !section.dropdown"
                            v-click-outside="{ handler: () => section.dropdown = false, include: includeFunction }"
                            class="fal fa-plus icon"></i>
                        <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
                            <div v-if="section.dropdown" class="c-dropdown animated-fast">
                                <ul>
                                    <li @click.stop="addSection(s)">
                                        Add Section
                                    </li>
                                    <li
                                        @click.stop="section.addElement = !section.addElement, section.dropdown = false">
                                        Add Element
                                    </li>
                                </ul>


                            </div>
                        </transition>
                        <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
                            <div v-click-outside="{ handler: () => section.addElement = false, include: includeFunction }"
                                v-if="section.addElement" class="d-dropdown animated-fast">
                                <div>Add Element</div>
                                <v-select :items="availableElementTypes" :color="appColor" x-small dense
                                    label="Element Type" outlined v-model="addElement.type"></v-select>
                                <v-form @submit.prevent="submitAddElement(s)" ref="form" v-model="valid"
                                    lazy-validation>
                                    <!-- DATE OPTIONS -->
                                    <div v-if="addElement.type == 'date'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = section.section_name.replaceAll('-', '').replaceAll(' ', '-') + '-'+ $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TIME OPTIONS -->
                                    <div v-if="addElement.type == 'time'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = section.section_name.replaceAll('-', '').replaceAll(' ', '-') + '-'+ $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- STRING OPTIONS -->
                                    <div v-if="addElement.type == 'string'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = section.section_name.replaceAll('-', '').replaceAll(' ', '-') + '-'+ $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TEXTAREA OPTIONS -->
                                    <div v-if="addElement.type == 'textarea'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = section.section_name.replaceAll('-', '').replaceAll(' ', '-') + '-'+ $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- NUMBER OPTIONS -->
                                    <div v-if="addElement.type == 'number'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = section.section_name.replaceAll('-', '').replaceAll(' ', '-') + '-'+ $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- CHECKBOX OPTIONS -->
                                    <div v-if="addElement.type == 'boolean'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = section.section_name.replaceAll('-', '').replaceAll(' ', '-') + '-'+ $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>

                                    <!-- CHECKBOX W/ COMMENT OPTIONS -->
                                    <div v-if="addElement.type == 'boolean_w_comment'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = section.section_name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-').replaceAll('---', '-').replaceAll('--', '-') + '-'+ $event.toLowerCase().replaceAll(' ', '-').replaceAll(/\//g, '-').replaceAll(',', '')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>

                                    <!-- SELECT OPTIONS -->
                                    <div v-if="addElement.type == 'select'" class="element-options">
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field :rules="labelRules" hide-details :color="appColor" block
                                                    @input="$event => addElement.prop = section.section_name.replaceAll('-', '').replaceAll(' ', '-') + '-'+ $event.toLowerCase().replaceAll(' ', '-')"
                                                    v-model="addElement.label" outlined label="Label" dense />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-text-field hide-details :color="appColor" block
                                                    v-model="addElement.prop" outlined label="db prop" dense />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div
                                                style="display:flex; max-width:300px; align-items: center; flex-wrap: wrap; gap:5px; margin-top:15px;">
                                                <v-chip v-for="(option, o) in addElement.options" :key="o" dense small
                                                    v-bind="attrs" :input-value="selected" close @click="select"
                                                    @click:close="addElement.options.splice(addElement.options.indexOf(option), 1)">
                                                    <strong v-if="JSON.stringify(option).indexOf('{') > -1">{{ JSON.parse(JSON.stringify(option)).text }}</strong>
                                                    <strong v-else>{{ option }}</strong>

                                                </v-chip></div>
                                            <div class="e-value">
                                                <v-text-field  @keydown.enter.prevent.stop chip hide-details :color="appColor" block v-model="addElement.tempOption.text" outlined label="Option Label" dense />
                                                <v-text-field @keydown.enter.prevent.stop chip hide-details :color="appColor" block v-model="addElement.tempOption.value" outlined label="Option Value" dense />
                                                <v-btn style="margin-top:22px;" hide-details dark :color="appColor" depressed small @click="addElement.options.push({text:addElement.tempOption.text, value:addElement.tempOption.value}), addElement.tempOption.text = '', addElement.tempOption.value = ''">Add Option</v-btn>
                                               
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="e-value">
                                                <v-textarea rows="2" hide-details :color="appColor" block
                                                    v-model="addElement.alternative_text" outlined label="Description"
                                                    dense></v-textarea>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="e-value">
                                                <v-switch :color="appColor" label="Optional" dense
                                                    v-model="addElement.optional" />
                                            </div>
                                        </div>
                                    </div>



                                    <v-btn type="submit" x-small dark :color="appColor" depressed>Add Element</v-btn>
                                </v-form>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="form-actions">
                    <v-btn depressed :color="appColor" :loading="formLoading" @click="createForm" dark>Save &
                        Close</v-btn>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'create-form',
    data() {
        return {
            isLoading: false,
            formLoading: false,
            formConfig:null,
            valid: false,
            labelRules: [
                v => !!v || 'Label is required'
            ],

            addElement: {
                type: 'select one',
                label: '',
                options: [],
                alternative_text: '',
                addElement: false,
                tempOption: {
                    text: '',
                    value: ''
                },
                optional: false,
                prop: '',
            },
            config:{
                name:'New Form',
                instructions:'',
                layout:[
                    {
                        section_name:'New Section',
                        dropdown:false,
                        addElement:false,
                        rows:[

                        ]
                    }
                ],
                fields:[

                ]
            }
        }
    },
    mounted() {
        let id = this.$route.params.id;
        if(id){
            this.isLoading = true;
            axios.get(`/api/forms/get_form_details/${id}`).then(({data})=>{
                let r = data.result;
                
                r.layout.forEach((section)=>{
                    section.dropdown = false;
                    section.addElement = false;
                    section.expanded = false;
                })
                r.fields.forEach((field)=>{
                    
                    let obj = {
                        type: 'select one',
                        label: '',
                        options: [],
                        alternative_text: '',
                        optional: false,
                        prop: '',
                        tempOption: {
                            text: '',
                            value: ''
                        },
                        addElement:false
                    };
                    if(field.type == null){
                        field.type = obj.type;
                    }
                    if(field.options == null){
                        field.options = obj.options;
                    }
                    if(field.alternative_text == null){
                        field.alternative_text = obj.alternative_text;
                    }
                    if(field.optional == null){
                        field.optional = obj.optional;
                    }
                    if(field.optional == null){
                        field.optional = obj.optional;
                    }
                    field.addElement = false;
                   
                    
                    
                })
                this.config = r;
                this.isLoading = false;
            }).catch((e)=>{
                console.log(e);
                this.isLoading = false;
            })
        }
    },
    methods: {
        setAddElement(el){
            let obj = {
                type: 'select one',
                label: '',
                options: [],
                alternative_text: '',
                optional: false,
                prop: '',
                tempOption: {
                    text: '',
                    value: ''
                },
                addElement:false
            }
            
            this.addElement = Object.assign(obj, el);
        },
        getIconClass(type) {
            if (type == 'date') {
                return 'fa-calendar'
            }
            if (type == 'boolean') {
                return 'fa-check-square'
            }
            if (type == 'time') {
                return 'fa-clock'
            }
            if (type == 'string') {
                return 'fa-text'
            }
            if (type == 'textarea') {
                return 'fa-text-size'
            }
            if (type == 'number') {
                return 'fa-calculator'
            }
            if (type == 'select') {
                return 'fa-stream'
            }
            if (type == 'boolean_w_comment') {
                return 'fa-money-check-edit'
            }
        },
        editField(sectionidx, rowidx, propidx) {
            // let prop = this.config.layout[sectionidx].rows[rowidx].props.splice(propidx, 1);
            this.editField = this.config.layout[sectionidx].rows[rowidx].props[propidx];
            let field = this.config.fields[this.config.fields.findIndex((item) => item.prop == this.editField)];
            // this.config.fields.splice(this.config.fields.findIndex((item) => item.prop == prop[0]), 1);

        },
        removeField(sectionidx, rowidx, propidx) {
            
            let prop = this.config.layout[sectionidx].rows[rowidx].props[propidx]
           
            
            for(let i = 0; i < this.config.layout.length;i++){
                for(let r = 0; r < this.config.layout[i].rows.length;r++){
                    for(let p = 0; p < this.config.layout[i].rows[r].props.length;p++){

                        if(this.config.layout[i].rows[r].props[p] == prop){
                            this.config.layout[i].rows[r].props.splice(p, 1);

                            
                            
                            if(this.config.layout[i].rows[r].props.length == 0){
                                
                                this.config.layout[i].rows.splice(r, 1);
                            }
                        }
                    }
                }
            }
            this.config.fields.splice(this.config.fields.findIndex((item) => item.prop == prop[0]), 1);

        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        reset() {
            this.$refs.form.reset()
        },
        validate(prop) {
            const formValid = this.$refs.form[0].validate();
            let allValid = true;
            let propToCheck = this.addElement.prop;
            if(prop != this.addElement.prop){
                if (this.config.fields.filter((item) => item.prop == propToCheck).length > 0) {
                
                this.$set(this.addElement, 'errorMessage', 'Property already exists');
                
                allValid = false;
            }
            }
            
            if(formValid && allValid){
                this.addElement.errorMessage = null;
            }
            return formValid && allValid;
        },
        submitAddElement(idx) {
            console.log(idx);
            let d = this.validate();
            if (!d) return;
            this.config.fields.push(JSON.parse(JSON.stringify(this.addElement)));
            this.config.layout[idx].rows.push({ props: [this.addElement.prop] });

            this.clearDropdowns();
        },
        submitEditElement(idx, rdx, pdx, prop) {
            
            let d = this.validate(prop);
            if (!d) return;
            this.config.fields[this.config.fields.findIndex((item) => item.prop == prop)].addElement = false;
            this.config.fields.splice(this.config.fields.findIndex((item) => item.prop == prop), 1);
            this.config.fields.push(JSON.parse(JSON.stringify(this.addElement)));
            this.config.layout[idx].rows[rdx].props[pdx] = this.addElement.prop;

            
            
        },
        deleteSection(idx) {
            this.config.layout.splice(idx, 1);
        },
        includeFunction() {
            //visible elements
            let tempArray = [];
            if (document.getElementsByClassName('v-select-list')) {
                //push the element to array when it is visible
                tempArray = tempArray.concat(Array.from(document.getElementsByClassName('v-select-list')))

            } else {
                setTimeout(() => {
                    this.addElement.type = 'select one'
                }, 300)
            }
            return tempArray
        },
        async createForm() {
            this.isLoading = true;
            if(this.$route.params.id != null){
                axios.put(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/edit/${this.$route.params.id}`, this.config).then(({data})=>{
                    this.isLoading = false;
                    this.$router.push('/forms');
                }).catch((e)=>{
                    this.isLoading = false;
                    window.alert('An error occurred');
                    console.log(e);
                })  
            }
            try {
                console.log('created');
            } catch (e) {
                console.log(e);
            }
        },
        addSection(idx) {
            this.config.layout.splice(idx+1, 0, {
                section_name: 'New Section',
                dropdown: false,
                addElement: false,
                expanded: true,
                rows: []
            })
            this.clearDropdowns();
        },
        clearDropdowns() {

            this.addElement = {
                type: 'select one',
                alternative_text: '',
                label: '',
                options: [],
                optional: false,
                addElement: false,
                tempOption: {
                    text: '',
                    value: ''
                },
                prop: '',
            };
            for (let i = 0; i < this.config.layout.length; i++) {
                this.config.layout[i].dropdown = false;
                this.config.layout[i].addElement = false;
            }
        }
    },
    computed: {
        appColor() {
            if (this.facility == null) return '#4db7c9';
            return this.facility == 'oasis' ? '#a6855d' : '#4db7c9';
        },
        availableElementTypes() {
            return [

                {
                    text: 'Date',
                    value: 'date'
                },
                {
                    text: 'Text Input',
                    value: 'string'
                },
                {
                    text: 'Number Input',
                    value: 'number'
                },
                {
                    text: 'Select',
                    value: 'select'
                },
                {
                    text: 'Time',
                    value: 'time'
                },
                {
                    text: 'Text Box',
                    value: 'textarea'
                },
                {
                    text: 'Checkbox',
                    value: 'boolean'
                },
                {
                    text: 'Checkbox w/ Comment',
                    value: 'boolean_w_comment'
                }


            ]
        }
    },
    watcH: {

    }
}
</script>
<style lang="scss">
.editElement{
        .element-options{
            .row{
                margin:0;
                .e-value {
                width: 100%;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .v-label {
                    text-transform: none;
                    letter-spacing: 0;
                    font-weight: normal;
                }
            }
            }
        }
    }
.add-form-element {
    .icon {
        opacity: .5;
        height: 35px;
        width: 35px;
        background: #ffff;
        cursor: pointer;
        border-radius: 3px;
        color: #808080;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13pt;
        border: solid 1px #cecece;
        transition: all .3s ease;
        user-select: none;

        &:hover {
            opacity: 1;
            background: darken(#eaeaea, 10%);
        }

        &:active {
            transition: none;
            background: darken(#eaeaea, 20%);
        }
    }

    position: relative;

    .c-dropdown {
        background: #fff;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 5px;
        z-index: 1000;
        margin-bottom: 0;
        padding-bottom: 0;

        ul {
            padding: 0;

            margin: 0;

            li {
                padding: 7px 50px;
                transition: all .3s ease;
                cursor: pointer;
                user-select: none;

                &:hover {
                    background: darken(#fff, 5%);
                }
            }
        }
    }

    .d-dropdown {
        background: #fff;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 100%;
        left: 0;
        padding: 15px;
        border-radius: 5px;
        z-index: 1000;


    }
    
    .element-options {
        margin-bottom: 15px;
        width: 100%;
        max-width: 300px;
        display: flex;
        flex-direction: column;

        .v-text-field .v-label--active {
            // transform: translateY(-20px) scale(.75) !important;
            // top:0px !important;
        }

        position:relative;

        .v-input {
            padding-top: 0px;

        }

        .v-textarea {
            // padding-top:0px !important;
        }

        .row {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0px 0px;
            margin: 0;

            .e-title {
                font-size: 11pt;
                font-weight: normal;
            }

            .e-value {
                width: 100%;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .v-label {
                    text-transform: none;
                    letter-spacing: 0;
                    font-weight: normal;
                }
            }
        }
    }

}

.s-title-input {
    transition: all .3s ease;
    font-size: 15pt;
    color: #000;
    font-weight: bold;
    margin-bottom: 15px;
    padding: 10px;
    border: solid 1px transparent;
    border-radius: 3px;
    width:500px;
    &::placeholder {
    color: #80808049;
    }
    &:hover {
        color: var(--app-color);
        border: solid 1px var(--app-color);
    }

    &:focus {
        padding: 10px;
        color: #000 !important;
        border: solid 1px var(--app-color);
    }
}

.trash-section {
    width: 35px;
    height: 35px;
    border: solid 1px #eaeaea;
    border-radius: 3px;
    transition: all .3s ease;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center;
    cursor: pointer;
    font-size: 10pt;
    margin-left: auto;
    &::before{
        transition:all .3s ease;
    }
    &.rotated{
        &::before{
            transform:rotate(180deg);
        }
       
    }
    &:hover {
        color: #ff0000;
        background: darken(#fff, 5%);
    }
}

.element-row {
    display: flex;
    align-items: center;

    .element-item {
        padding: 15px;
        font-size: 12pt;
        font-weight: bold;
        border: solid 1px #eaeaea;
        width: 100%;
        border-radius: 3px;
        display: flex;
        align-items: flex-start;
        transition: all .3s ease;

        &:hover {
            border-color: #000;
        }

        .element-desc {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 5px;

            span {
                font-size: .8em;
                color: #aeaeae;
                font-weight: normal;
            }
        }

        .fa-trash {
            width: 35px;
            height: 35px;
            border: solid 1px #eaeaea;
            border-radius: 3px;
            transition: all .3s ease;
            display: inline-flex !important;
            align-items: center !important;
            justify-content: center;
            cursor: pointer;
            font-size: 10pt;
            margin-left: auto;

            &:hover {
                color: #ff0000;
                background: darken(#fff, 5%);
            }
        }

        .fa-pencil {
            width: 35px;
            height: 35px;
            border: solid 1px #eaeaea;
            border-radius: 3px;
            transition: all .3s ease;
            display: inline-flex !important;
            align-items: center !important;
            justify-content: center;
            cursor: pointer;
            font-size: 10pt;
            margin-left: auto;

            &:hover {
                color: #197aa3;
                background: darken(#fff, 5%);
            }
        }

    }
}

.s-field {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
}

.form-name {
    transition: all .3s ease;
    border-radius: 3px;
    padding: 3px 7px;
    border: solid 1px transparent;
    width: 100%;
    &:hover {
        border: solid 1px #000;
    }

    &:focus {
        border: solid 1px #000;
    }
}

.form-desc {
    font-size: 10pt;
    border: dashed 1px transparent;
    transition: all .3s ease;
    border-radius: 3px;
    width: 100%;
    margin-top: 15px;
    padding: 5px 8px;
    color: #000;
    font-weight: 400;

    &:hover {
        border: dashed 1px #000;
    }

    &:focus {
        border: dashed 1px #000;
    }
}

.option-chip {
    color: #fff !important;
    border-radius: 100px;
    padding: 3px 17px;
    background: #000;
}

.type-tag {
    color: #fff !important;
    border-radius: 100px;
    padding: 3px 17px;
    background: #808080;

    &.e-string {
        background: #ff9900;
    }

    &.e-boolean {
        background: #3080e8;
    }

    &.e-date {
        background: #50b84a;
    }

    &.e-select {
        background: #8a3fb2;
    }

    &.e-time {
        background: #3e9c75;
    }

    &.e-textarea {
        background: #554937;
    }

    &.e-number {
        background: #6b2d3d;
    }

    &.e-boolean_w_comment {
        background: #259ba3;
    }
}
</style>