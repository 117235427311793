<template>
    <div class="d-input" :class="{'dark-d':dark}">
        <label :class="{'filled':value != ''}">{{label}}</label>
        <input :placeholder="placeholder" @input="$emit('input', $event.target.value)" :key="1" :value="value" v-mask="mask" :name="label"   :type="type">
        <div class="bottom-line"></div>
    </div>
</template>
<script>
export default {
    name:'text-input',
    props:{
        mask:{
          type:String,
          default:null
        },
        dark:{
            type:Boolean,
            default:false
        },
        placeholder:{
            type:String,
            default:'',
        },
        color:{
            type:String,
            default:'#3e80e3'
        },
        value:{
            type:String,
            default:''
        },
        label:{
            type:String,
            default:''
        },
        type:{
            type:String,
            default:'text'
        },
        large:{
            type:Boolean,
            default:false
        },
        'x-large':{
            type:Boolean,
            default:false
        },
        fab:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style lang="scss">
    .d-input{
        text-align: left;
        position:relative;
        margin-top:25px;
        flex:1;
        background:#ffffff20;
        border-radius: 10px;
        padding:0;
        padding:5px 10px;
        input{
            color:#000;
            padding:5px 0px;
            font-size:12pt;
            width:100%;
            &:focus{
                outline:none;
            }
        }
        &:focus-within{
            label{
                max-width:153%;
                transform:translateY(-26px) translateX(0px) scale(.7,.7);
            }
            .bottom-line{
                background:#000;
            }
        }
        .bottom-line{
            height:1px;
            background:#00000030;
            width:100%;
            
            position:absolute;
            bottom:0;
            left:0;
        }
        .filled{
                max-width:153%;
                transform:translateY(-26px) translateX(0px) scale(.7,.7) !important;
            }
        label{
            max-width:90%;
            transform-origin: top left;
            transition:all .3s ease;
            width:200%;
            pointer-events: none;
            position:absolute;
            left:10px;
            top:10px;
            overflow: hidden;
            padding:0px;
            font-size:12pt;
            white-space: nowrap;
            text-overflow: ellipsis;
            color:#000;
        }

        &.dark-d{
            label{
                color:#fff;
            }
            input{
                color:#fff;
            }
            .bottom-line{
                background:transparent;
            }
        &:focus-within{
            label{
                max-width:153%;
                transform:translateY(-25px) translateX(0px) scale(.7,.7);
            }
            .bottom-line{
                background:transparent;
            }
        }
        }
    }
</style>