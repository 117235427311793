<template>
    <div class="inner-stage">
        <div style="display:flex; height:calc(100vh - 186px)">
        <v-row class="fill-height">
    <v-col>
      <v-sheet style="background-color:transparent !important" height="64">
        <v-toolbar
          text
          depressed
          flat
          style="background-color:transparent !important"
        >
          <v-btn
            outlined
            class="mr-4"
            :color="appColor"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
          style="margin-right:10px;"
            fab
            text
            icon
            x-small
            color="#000"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            x-small
            icon
            color="#000"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title style="margin-left:10px;" v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                :color="appColor"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet style="background-color:transparent !important" height="600">
        <v-calendar
        style="border-radius: 10px; border:none; overflow:hidden; box-shadow:0px 10px 20px -8px rgba(0,0,0,0.15)"
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
        max-width="350px"
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            
            text
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              
              <v-toolbar-title>
              {{ selectedEvent.message }} 
              </v-toolbar-title>
              <v-spacer></v-spacer>
              
            </v-toolbar>
            <v-card-text>
              <span >{{selectedEvent.message}} <v-btn small dense dark text depressed :color="appColor" v-if="selectedEvent.type == 'form'" @click="$router.push(`/forms/${selectedEvent.formId}/new`)">Go to Form</v-btn></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
  <div class="reminders">
        <div class="reminder-item" v-for="(item, i) in remindersToShow" :key="i">
            <div>
            <div class="reminder-title"><i class="fad fa-bell"></i>&nbsp;&nbsp;{{ moment(item.date).format('MM/DD/YYYY') }}</div>
            <div v-if="item.lead_id != null" class="reminder-lead" @click="$router.push(`/lead/${item.lead_id}`)" >
                {{ item.name }}
            </div>
            <div  v-if="item.user_name != null" class="reminder-value">{{ item.user_name }}</div><br>
            <div class="reminder-value">{{ item.message }}</div>
            </div>
            <div class="reminder-actions">
                <v-btn :loading="reminderLoading == i" small dense dark text depressed :color="appColor" v-if="item.type != 'form'" @click="dismissReminder(item._id, i)">dismiss</v-btn>
                <v-btn :loading="reminderLoading == i" small dense dark text depressed :color="appColor" v-if="item.type == 'form'" @click="$router.push(`/forms/${item.formId}/new`)">Go to Form</v-btn>
            </div>
        </div>
        <div class="no-reminders" v-if="remindersToShow.length == 0">
            <i class="fad fa-exclamation-triangle"></i>&nbsp;&nbsp;No Reminders
        </div>
        </div>
      </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
    name:'reminders',
    data(){
        return {
            reminderLoading:-1,
            moment,
            isLoading:false,
            remindersToShow:[],
            //
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        }
    },
    mounted(){
        this.getLeadInformation();
        this.$refs.calendar.checkChange()
    },
    methods:{
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
            this.selectedOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
            open()
            }

            nativeEvent.stopPropagation()
        },
        updateRange () {
            const events = []
            for (let i = 0; i < this.remindersToShow.length; i++) {
             const first = this.moment(this.remindersToShow[i].date).startOf('day').toDate();
             const second = this.moment(this.remindersToShow[i].date).endOf('day').toDate();
            events.push({
                name: this.remindersToShow[i].name || this.remindersToShow[i].message.split(' need')[0],
                message:this.remindersToShow[i].message,
                formId: this.remindersToShow[i].formId,
                type:this.remindersToShow[i].type,
                start: first,
                end: second,
                color: this.colors[0],
                timed: false,
            })
            }
            this.events = events
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        //

        async getLeadInformation(){
            let r = [];
            for(let i of this.reminders){
                let name = i.lead_id != null ? await this.getLeadName(i.lead_id) : i.name
                r.push({...i, name})
            }

            this.remindersToShow = r;
        },
        async getLeadName(id){
           let {data} = await axios.get(`/api/leads/lead/${id.toString()}`);
           if(data.result){
            return data.result.name;
           }else{
            return '';
           }
           
        },
        async dismissReminder(id, idx){
            this.reminderLoading = idx;
            try{
                await axios.put(`/api/reminders/edit/${id}`, {dismissed:true})
                
                
                let { data } = await axios.get(`/api/reminders/all?facility=${this.facility}`);
                this.$store.dispatch('setReminders', data.items);
                this.getLeadInformation()
                this.reminderLoading = -1;
            }catch(e){
                this.getLeadInformation()
                this.reminderLoading = -1;
                window.alert('there was an error dismissing reminder');
            }
        }
    },  
    computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        ...mapGetters({
            reminders:'REMINDERS',
            facility:'FACILITY'
        })
    },
    watch:{
      remindersToShow(v){
        
        this.$nextTick(()=>{
          this.updateRange();
        })
      }
    }
}
</script>
<style lang="scss">
.reminders{
    display:flex; 
    flex-direction: column;
    max-width:300px;
    flex:1;
    height:100%;
    overflow: auto;
    gap:10px;
    padding:15px;
    .reminder-item{
        max-width:500px;
        width:100%;
        i{
            font-size:14pt;
            color:#000
        }
        text-align: left;
        display:flex;
        align-items: center;
        gap:10px;
        padding:25px;
        border-radius: 5px;
        border:solid 1px #00000034;
        .reminder-actions{
            margin-left:auto;
        }
        .reminder-lead{
            color:rgb(38, 80, 88);
            font-weight:bold;
            font-size:12pt;
            padding: 5px 0px;
            &:hover{
                color:var(--app-color);
            }
            transition: all .3s ease;
            cursor: pointer;
        }
    }
}
.no-reminders{
    height:120px;
    height:100%;
    width:100%;
    max-width:500px;
    border:solid 1px #00000010;
    border-radius: 4px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 12pt;
    color:#808080;
    background:#f8fafb;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside,.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background:#f8fafb !important;
}
</style>