<template>
    <div class="radio-group">
        <input style="pointer-events:none;opacity:0;position:absolute;" :style="`color:${color};`" ref="slo" type="text" v-model="value" />
        <slot ></slot>
        
    </div>
</template>
<script>
export default {
    name:'checkbox',
    data(){
        return {
            
        }
    },
    mounted(){
    this.$on('change',(v)=>{
        this.$refs.slo.value = v;
        this.$emit('input', v);
        
    })
    },
    methods:{
        
    },
    props:{
        color:{
            type:String,
            default:'#1d4562'
        },
        value:{
            type:String,
            default:0
        }
    }
}
</script>
<style lang="scss">
.radio-group{
    padding:15px;
    display:flex;
}
    
</style>