<template>
<div >
    <transition enter-active-class="fadeIn" leave-active-class="fadeOut" @after-enter="showContent = true">
    <div class="overlay-d animated-med" v-if="value">
        <transition mode="out-in" :key="1" appear enter-active-class="zoomIn" leave-class="zoomOut" leave-active-class="zoomOut">
        <div :key="2"  class="animated-med" :style="`max-width:1200px; width:${width}px`">
            <slot></slot>
        </div>
    </transition>
    </div>
    </transition>
</div>
</template>
<script>
export default {
    name:'dialog',
    data(){
        return {
            showContent:true
        }
    },
    props:{
        width:{
            type:Number,
            default:500
        },
        value:{
            type:Boolean,
            default:false
        }
        
    },
    watch:{
        value(v){
            console.log(v);
            if(!v){
               
                this.showContent = false;
            }else{
                this.showContent = true;
            }
        }
    }
}
</script>
<style lang="scss">
    .btn{
        padding:5px 15px;
        border-radius: 5px;
        &:hover{
            cursor: pointer;
        }
        
        color:#fff;
    }
    .small{
        padding:4px 10px;
        font-size:10pt;
    }
    .large{
        padding:15px 30px;
        font-size:17pt;
    }
    .x-large{
        padding:20px 40px;
        font-size:20pt;
    }
    .overlay-d{
        display: flex;
        align-items: center;
        justify-content: center;
        height:100vh;
        width:100%;
        position: fixed;
        background:#00000040;
        top:0;
        left:0;
        z-index: 10000000;
    }
</style>