<template>
    <div class="sidebar">
        <ul>
            <li v-if="role == 'admin' || permissions.includes('dashboard')" @click="$router.push('/dashboard')" :class="{'active': page == '/dashboard'}">
                <i class="fad fa-bars"></i> <span>Dash</span>
            </li>
            <li v-if="role == 'admin' || permissions.includes('leads')" @click="page == '/crm' ?($router.push('/crm'), window.location.reload()) : $router.push('/crm')" :class="{'active': page == '/crm'}">
                <i class="fad fa-users"></i> <span>CRM</span>
            </li>
            
            <li v-if="role == 'admin' || permissions.includes('reminders')" @click="$router.push('/reminders')" :class="{'active': page == '/reminders'}">
                <i class="fad fa-bell"><div class="reminder-ball" v-if="reminders.length > 0">{{ reminders.length }}</div></i> <span>Reminders</span>
                
            </li>
            <li v-if="role == 'admin' || permissions.includes('users')" @click="$router.push('/users')" :class="{'active': page == '/users'}">
                <i class="fad fa-user-circle"></i> <span>Users</span>
            </li>
            <li v-if="role == 'admin' || permissions.includes('forms')" @click="$router.push('/forms')" :class="{'active': page == '/forms'}">
                <i class="fad fa-file"></i> <span>Forms</span>
            </li>
            <li v-if="role == 'admin' || permissions.includes('inventory')" @click="$router.push('/inventory')" :class="{'active': page == '/inventory'}">
                <i class="fad fa-cube"></i> <span>Inventory</span>
            </li>
            <li v-if="role == 'admin' || permissions.includes('bed_chart')" @click="$router.push('/bedchart')" :class="{'active': page == '/bedchart'}">
                <i class="fad fa-bed"></i> <span>Bed Chart</span>
            </li>
            <li v-if="role == 'admin' || permissions.includes('expenses')" @click="$router.push('/expenses')" :class="{'active': page == '/expenses'}">
                <i class="fad fa-receipt"></i> <span>Expenses</span>
            </li>
            
        </ul>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name:'sidebar',
    data(){
        return {
            window:window
        }
    },methods:{

    },
    computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},  

    ...mapGetters({
      user:'AUTH_USER',
      reminders:'REMINDERS'
    }),
    role(){
      return this.user.role;
    },
    permissions(){
      return this.user.permissions;
    },
    page(){
        return this.$route.path
    }
    }
}
</script>
<style>
.v-tooltip__content{
    z-index: 1000 !important;
}
</style>
<style scoped lang="scss">
@import "@/assets/scss.scss";
.sidebar{
    
        background:#f8fafb;
    
   
    max-width:250px;
    min-width:250px;
    position:fixed;
    z-index: 100;
    top:70px;
    left:0;
    height:100%;
    flex:1;
    padding:0px 15px;
    ul{
        margin-top:55px;
        padding-left:25px;
        
        @include media-query($medium-down) {
            padding:0;
            margin:0;
            display:flex;
            justify-content: center;
            width:100%;
        }
        li{
            border-radius: 5px;
            position: relative;
            display:flex;
            align-items: center;
            margin:5px 0px;
            i{
                margin-right:15px;
                font-size:12pt;
                color:var(--app-color);
                width:30px;
                transition:all .3s ease;
            }
            list-style: none;
            padding:10px;
            font-size:12pt;
            
            font-weight: lighter;
            
            
            color:var(--app-color);
            transition:all .3s ease;
            cursor: pointer;
            &.active{
                background:var(--app-color)!important;
                color:#fff !important;
                i{
                    color:#fff !important;
                }
            }
            &:hover{
                background:#00000005;
                i{
                    transform:scale(1.15, 1.15);
                }
            }
            @include media-query($medium-down) {
                display:flex;
                flex-direction: column;
                align-items: center;
                span{
                    margin-top:5px;
                    font-size:7pt;
                }
                i{
                    margin:0;
                }
            }
        }
    }
    @include media-query($medium-down) {
        position:fixed;
        bottom:0;
        left:0;
        width:100%;
        display:flex;
        height:55px;
        max-width:unset;
        min-width:unset;
        z-index:100;
        top:unset;
        border-top:solid 1px #eaeaea;
  }
}
.reminder-ball{
    height: 14px;
    width:14px;
    border-radius: 14px;
    background:#ff0000;
    display:flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    font-size:6pt;
    position:absolute;
    top:0;
    font-weight: bold;
    right:0;
    z-index: 100;
}
</style>