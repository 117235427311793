<template>
    <div class="radio-btn">
        
        <input :style="`color:${color};`" @change="toggle" name="radio-1" :value="label" :checked="$parent.value == value" type="radio">
        <label :style="`color:${color};`" for="">{{label}}</label>
        
    </div>
</template>
<script>
export default {
    name:'checkbox',
    props:{
        color:{
            type:String,
            default:'#1d4562'
        },
        value:{
            type:String,
            default:'Yes'
        },
        label:{
            type:String,
            default:''
        }
        
    },
    methods:{
        toggle(){
            
            this.$parent.$emit('change', this.value)
        }
    }
}
</script>
<style lang="scss">
    .radio-btn{
        align-items: center;
        .input{
            margin-right:10px;
        }
        label{
            padding:10px;
            display:inline-block;
        }
        display:flex;
    }
</style>