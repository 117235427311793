<template>
    <div class="container">
    <div class="loginForm">
        <div class="innerForm">
        <div class="loginLogo">
            <img v-if="facility == 'oasis'" src="@/assets/oasis_logo_small.png" alt="">
            <img v-else src="@/assets/ohana_logo.webp" alt="">
        </div>
        <div>
            <div style="display:flex;">
                <v-text-field outlined  class="animated-fast fadeInUp" v-model="password" style="margin-right:10px;" background-color="transparent" :color="appColor" type="password" label="New Password"></v-text-field>
            </div>
            <v-btn  @click="register" class="loginBtn" dark :color="appColor" depressed><span style="text-transform: none">SET PASSWORD</span></v-btn>
            <transition enter-active-class="shake" leave-active-class="fadeOut">
                <div class="errorMessage animated" v-if="hasError">{{errorMessage}}</div>
            </transition>
            <transition enter-active-class="tada" leave-active-class="fadeOut">
                    <div class="successMessage animated"  v-if="hasSuccess">{{successMessage}}</div>
            </transition>
            <v-btn v-if="displaySuccessButton" class="loginBtn" @click="$router.push('/')" :color="appColor" dark depressed>Go to Login</v-btn>
        </div>
    </div>
        
    </div>
    
</div>
</template>
<script>
import auth from '../auth';
import jwtDecode from 'jwt-decode';
import { mapGetters } from 'vuex';
export default {
    name:'login',
    data(){
        return {
            password:'',
            errorMessage:'',
            hasError:false,
            successMessage:'Password successfully changed!',
            displaySuccessButton:false
        }
    },
    computed:{
        ...mapGetters({
            facility:'FACILITY'
        }),
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        email(){
            return jwtDecode(this.$route.query.token).email;
        },
        path(){
            return this.$route.query.token
        }
    },
    methods:{
        register(){
            if(this.email.indexOf('@') > -1){
              this.hasError = false;
            auth.resetPassword({email:this.email, password:this.password}).then(res=>{
                this.hasSuccess = true;
                this.displaySuccessButton = true;
            },err=>{
                this.hasError = true;
                this.errorMessage = err.response.data.error
            })  
            }else{
                this.hasError = true;
                this.errorMessage = 'Link broken or token expired.'
            }
            

        }
    }
}
</script>
<style scoped lang="scss">
.loginForm{
    border-radius: 10px;
    min-width: 340px;
    text-align: center;
    background: #fff;
    box-shadow: 0px -0px 39px -10px #00000050;
    overflow: hidden;
}
.innerForm{
    padding:45px;
}
.loginLogo{
    display:inline-block;
    font-size:20pt; font-weight: 500;
    color:#808080;
    margin-bottom:50px;
    white-space: nowrap;
    text-align: center;
    width:100%;
}
.loginLogo img{
    margin-bottom:15px;
    width:220px
}
.container{
    display:flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    width:100vw;

    
    background-size:cover;
    background-position: center;
}
.loginBtn{
    width:100%;
    border-radius: 3px;
    margin:0 !important;
    margin-top:25px !important;
    
}
.errorMessage{
    background:#e9eaea;
    color:#ff0000;
    font-weight: 300;
    font-size:10pt;
    padding:15px;
    margin-top:10px;
}
.successMessage{
    background:#e9eaea;
    color:#19b466;
    font-weight: 300;
    font-size:10pt;
    padding:15px;
    margin-top:10px;
}
.link{
    cursor: pointer;
    color:#fff;
    text-decoration: underline;
}
</style>