import jwt_decode from "jwt-decode";

export default {
  state: {
    user: {},
    users:[]
  },
  mutations: {
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_USERS(state, payload) {
      let p = JSON.parse(JSON.stringify(payload))
      for(let i = 0; i < p.length; i++){
        state.users[i] = {...p[i], _id:p[i]._id};
      }

    }
  },
  getters: {
    AUTH_USER(state){
      return state.user;
    },
    AUTH_USERS(state){
      return state.users;
    }
  },
  actions: {
   setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    setUsers({ commit }, payload) {
      commit("SET_USERS", JSON.parse(JSON.stringify(payload)));
    },
    setToken(context, payload) {
      var decoded = jwt_decode(payload);
      context.commit("SET_USER", decoded);
    }
  }
};
