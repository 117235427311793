<template>
    <div class="inner-stage">
        <v-dialog width="500" :dismissable="false" v-model="sigOpen">
            <v-card>
                <v-card-title>
                    Add Signauture
                </v-card-title>
                <v-card-text>
                    <div class="sigy">
                        <span>Signature</span>
                        <vueSignature ref="signature" :sigOption="option" :w="'100%'" :h="'150px'"></vueSignature>
                        <br>

                        <v-btn text small compact @click="clear">Clear</v-btn><br>
                        <div class="sig-error" v-if="sigError">Signature cannot be blank</div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn small @click="sigOpen = false; $refs.signature.clear()" :disabled="signLoading" text
                        depressed>Cancel</v-btn>
                    <v-btn small dark :color="appColor" depressed :loading="signLoading" @click="sign">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div v-if="isLoading"><v-progress-circular color="#eaeaea" indeterminate :size="50"
                :width="5"></v-progress-circular></div>
        <div class="form-container">

            <div v-if="formDetails != null && !isLoading && role == 'admin'" class="admin-edit-container">
                <!-- Refrigerator Config -->
                <div v-if="formDetails.name == 'Refrigerator/Freezer Temp Log'" class="admin-edit-block">
                    <div class="admin-edit-options">
                        <div v-for="(item, i) in formDetails.fields[formDetails.fields.findIndex((it) => it.prop == 'fridge_id')].options"
                            :key="i" class="admin-edit-option">
                            <i @click="formDetails.fields[formDetails.fields.findIndex((it) => it.prop == 'fridge_id')].options.splice(i, 1), updateForm()"
                                class="fal fa-trash"></i> {{ item.text }}

                        </div>
                        <div><input class="add-option-input" type="text" v-model="freezerName"> <v-btn
                                @click="formDetails.fields[formDetails.fields.findIndex((it) => it.prop == 'fridge_id')].options.push({ text: freezerName, value: freezerName }), updateForm(), freezerName = ''"
                                x-small dark depressed :color="appColor">Add</v-btn></div>
                    </div>
                </div>

                <!-- BREATHALIZER Config -->
                <div v-if="formDetails.name == 'Breathalyzer'" class="admin-edit-block">
                    <div class="admin-edit-options">
                        <div v-for="(item, i) in formDetails.layout[1].template[formDetails.layout[1].template.findIndex((it) => it.prop == 'name')].options"
                            :key="i" class="admin-edit-option">
                            <i @click="formDetails.layout[1].template[formDetails.layout[1].template.findIndex((it) => it.prop == 'name')].options.splice(i, 1), updateForm()"
                                class="fal fa-trash"></i> {{ item.text }}

                        </div>
                        <div><input class="add-option-input" type="text" v-model="breathalyzerName"> <v-btn
                                @click="formDetails.layout[1].template[formDetails.layout[1].template.findIndex((it) => it.prop == 'name')].options.push({ text: breathalyzerName, value: breathalyzerName }), updateForm(), breathalyzerName = ''"
                                x-small dark depressed :color="appColor">Add</v-btn></div>
                    </div>
                </div>

                <!-- Hand Hygiene Config -->
                <div v-if="formDetails.name == 'Hand Hygiene Monitoring Log'" class="admin-edit-block">
                    <div class="admin-edit-options">
                        Hand Hygiene Compliance Percentage: {{ handHygieneCompliancePercent }}
                    </div>
                </div>



            </div>
        </div>
        <div v-if="formDetails != null && !isLoading && !isRunning && !isInventory && show" class="form-container">
            <v-menu v-model="dateMenu" :close-on-content-click="false"
                v-if="formDetails.name == 'Hand Hygiene Monitoring Log'" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field :color="appColor" v-model="monthFormatted"
                        style="padding-top:0 !important; max-width:250px;"
                        @blur="month = parseDate(monthFormatted), monthFormatted = formatDate(month)" class="fixMe"
                        outlined hide-details small dense readonly v-bind="attrs" v-on="on"
                        @click:clear="month = null"></v-text-field>
                </template>
                <v-date-picker :color="appColor" type="month" v-model="month"
                    @change="($event) => { month = $event; monthFormatted = formatDate($event), dateMenu = false; getFormsFilled() }"></v-date-picker>
            </v-menu>
            <v-select v-if="formDetails.name == 'Emergency Lighting Battery Testing (monthly)'" outlined
                :color="appColor" style="display:inline-block" v-model="year" @change="($event) => { getFormsFilled() }"
                label="Year" :items="['2021', '2022', '2023', '2024', '2025', '2026']"></v-select>
            <br>

            <div class="f-name"><i class="fad fa-file"></i>{{ formDetails.name }} 
                    <v-btn @click="$router.push(`/forms/${$route.params.id}/new`)" text depressed small dense dark :color="appColor">
                        Add&nbsp; <i style=" margin:0;" class="fal fa-plus"></i> 
                    </v-btn> 
                    <v-btn small text :color="appColor" @click="exportPdf">export PDF</v-btn>
                    <v-btn v-if="true" text depressed small dense dark :color="appColor" @click="$router.push(`/forms/create/${$route.params.id}`)">Edit Form</v-btn>
            </div>
            <div class="f-table-total">{{ total }} Total Records </div>
            <v-client-table v-if="!isLoading" ref="my-table" :data="formsFilled" :columns="columns"
                :options="{ perPage: 50 }" @sorted="sortMe">
                <div slot="date" style="white-space: nowrap;" slot-scope="{row}">
                    <i v-if="role == 'admin' && row._id != null" @click="deleteFormFill(row._id)"
                        class="fal fa-trash trashentry"></i> {{
                            moment(row.date).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}
                </div>
                <div slot="used" style="white-space:nowrap" slot-scope="{row}">
                    {{ row.used ? 'Yes' : 'No' }}
                </div>
                <div slot="status" style="white-space:nowrap" slot-scope="{row}">
                    <div class="status-tag" :class="row.status">{{ row.status.replaceAll('_', ' ') }}</div>
                </div>
                <div slot="date_disposed" slot-scope="{row}">
                    {{ moment(row.date_disposed).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}
                </div>
                <div slot="id" slot-scope="{row}">

                    <a @click="$router.push(`/forms/${$route.params.id}/edit/${row.id}`)"
                        style="white-space: nowrap;"><i class="fal fa-eye"></i></a>
                </div>
                <div slot="_id" slot-scope="{row}">
                    <a :style="`white-space:nowrap; background-color: ${appColor}; padding:5px 10px; color:#fff; border-radius:5px;`" @click="$router.push(`/forms/${$route.params.id}/edit/${row._id}`)" v-if="formDetails.name == 'Medication/Substance Disposal' && !row.complete">
                        Complete Form
                    </a>
                    <a v-else @click="$router.push(`/forms/${$route.params.id}/edit/${row._id}`)"
                        style="white-space: nowrap;"><i class="fal fa-eye"></i></a>
                    </div>
                <div slot="lights" slot-scope="{row}">


                    <div :id="`${row._id}-light-test`"
                        v-if="formDetails.name != 'Smoke Alarm Testing & Inspection Log'">
                        <div v-for="(light, i) in row.lights" :key="i">
                            {{ light.name }}: <i class="fal"
                                :class="{ 'fa-square': !light.fields[0].value, 'fa-check-square': light.fields[0].value }"></i>
                            <span style="font-weight: bold;">{{ light.fields[0].label }}</span> <i class="fal"
                                :class="{ 'fa-square': !light.fields[1].value, 'fa-check-square': light.fields[1].value }"></i><span
                                style="font-weight: bold;"> {{ light.fields[1].label }}</span>
                        </div>
                    </div>
                    <div v-if="formDetails.name == 'Smoke Alarm Testing & Inspection Log'">
                        <div v-for="(light, i) in row.lights" :key="i">
                            {{ light.name }}: &nbsp; <i class="fal"
                                :class="{ 'fa-square': !light.fields[0].value, 'fa-check-square': light.fields[0].value }"></i>
                            <span style="font-weight: bold;"> {{ light.fields[0].label }}</span>


                        </div>
                    </div>
                </div>
                <div slot="followed_up" style="white-space:nowrap; "  slot-scope="{row}">
                    <div class="followup-tag" :class="{ 'y': row.followed_up }">{{ row.followed_up ? 'Followed Up' : 'Not Followed Up'}}</div>
                </div>
                <div slot="detectors" slot-scope="{row}">


                    <div :id="`${row._id}-light-test`"
                        v-if="formDetails.name != 'Smoke Alarm Testing & Inspection Log'">
                        <div v-for="(light, i) in row.detectors" :key="i">
                            {{ light.name }}: <i class="fal"
                                :class="{ 'fa-square': !light.fields[0].value, 'fa-check-square': light.fields[0].value }"></i>
                            <span style="font-weight: bold;">{{ light.fields[0].label }}</span> <i class="fal"
                                :class="{ 'fa-square': !light.fields[1].value, 'fa-check-square': light.fields[1].value }"></i><span
                                style="font-weight: bold;"> {{ light.fields[1].label }}</span>
                        </div>
                    </div>
                    <div v-if="formDetails.name == 'Smoke Alarm Testing & Inspection Log'">
                        <div v-for="(light, i) in row.detectors" :key="i">
                            {{ light.name }}: &nbsp; <i class="fal"
                                :class="{ 'fa-square': !light.fields[0].value, 'fa-check-square': light.fields[0].value }"></i>
                            <span style="font-weight: bold;"> {{ light.fields[0].label }}</span>


                        </div>
                    </div>
                </div>
                <div slot="lights" slot-scope="{row}">
                    <div v-if="formDetails.name != 'Emergency Lighting Battery Testing (monthly)'">
                        <div v-for="(light, i) in row.lights" :key="i">
                            {{ light.name }} {{ light.fields[0].label }}:{{ light.fields[0].value }} {{
                            light.fields[1].label }}:{{
                            light.fields[1].value }}
                        </div>
                    </div>
                    <div v-if="formDetails.name == 'Emergency Lighting Battery Testing (monthly)'">
                        <div v-for="(light, i) in row.lights" :key="i">
                            {{ light.name }} {{ light.fields[0].label }}:<i class="fal"
                                :class="{ 'fa-square': !light.fields[0].value, 'fa-check-square': light.fields[0].value }"></i>
                        </div>

                    </div>

                </div>
                <div slot="date_removed_signature" slot-scope="{row}">
                    <img v-if="row._id != null && row.date_removed != ''" style="height:45px;"
                        :src="`/api/static/forms/${row._id}/date_removed_signature.jpg`" />
                </div>
                <div slot="expiration_date" style="white-space: nowrap;" slot-scope="{row}">

                    <span v-if="row.expiration_date != '' && row.expiration_date != null">{{
                        moment(row.expiration_date).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}</span>
                </div>
                <div slot="date_removed" style="white-space: nowrap;" slot-scope="{row}">
                    <v-btn v-if="row.date_removed == ''" x-small @click="sigOpen = true, activeRow = row" depressed
                        :color="appColor" dark>Remove Narcan</v-btn>&nbsp;&nbsp;
                    <span v-if="row.date_removed != '' && row.date_removed != null">{{
                        moment(row.date_removed).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}</span>
                </div>
                <div slot="date_stored" style="white-space: nowrap;" slot-scope="{row}">
                    <span v-if="row.date_stored != '' && row.date_stored != null">{{
                        moment(row.date_stored).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}</span>
                </div>
                <div slot="signature" slot-scope="{row}">

                    <img v-if="row._id != null" style="height:45px;"
                        :src="`https://crm.theohanahawaii.com/api/static/forms/${row._id}/signature.jpg`" />
                </div>
                <div slot="signature2" slot-scope="{row}">
                    
                    <img v-if="row._id != null && row.complete == true" style="height:45px;"
                        :src="`https://crm.theohanahawaii.com/api/static/forms/${row._id}/signature2.jpg`" />
                    <div style="font-size:.8em; color:#fff; background:#ff9900; border-radius: 100px; display:inline-block; padding:5px 10px;" v-else>INCOMPLETE</div>
                </div>
                <div slot="signature3" slot-scope="{row}">

                    <img v-if="row._id != null" style="height:45px;"
                        :src="`https://crm.theohanahawaii.com/api/static/forms/${row._id}/signature3.jpg`" />
                </div>

                <div slot="hh_moment" slot-scope="{row}">

                    <div style="padding:5px 7px; background:#eaeaea; border-radius: 100px;display:inline-block; font-size:8pt;white-space:nowrap; border:solid 1px #aeaeae;margin-bottom:5px;"
                        v-for="(mom, i) in row.hh_moment" :key="i">{{ mom }}</div>
                </div>
                <div style="white-space: nowrap;" slot="h__signature3">Signature of Client</div>
            </v-client-table>
        </div>

        <!-- IS RUNNING -->

        <div v-if="formDetails != null && !isLoading && isRunning && show" class="form-container">

            <div class="f-name"><i class="fad fa-file"></i>{{ formDetails.name }} <v-btn
                    @click="$router.push(`/forms/${$route.params.id}/new`)" text depressed small dense dark
                    :color="appColor">Add&nbsp; <i style=" margin:0;" class="fal fa-plus"></i> </v-btn> <v-btn small
                    text :color="appColor" @click="exportPdf">export PDF</v-btn></div>
            <div class="f-table-total">{{ total }} Total Records </div>
            <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field :color="appColor" v-model="monthFormatted"
                        style="padding-top:0 !important; max-width:250px;"
                        @blur="month = parseDate(monthFormatted), monthFormatted = formatDate(month)" class="fixMe"
                        outlined hide-details small dense readonly v-bind="attrs" v-on="on"
                        @click:clear="month = null"></v-text-field>
                </template>
                <v-date-picker :color="appColor" type="month" v-model="month"
                    @change="($event) => { month = $event; monthFormatted = formatDate($event), dateMenu = false; getFormsFilled() }"></v-date-picker>
            </v-menu><br>

            <v-client-table ref="my-table" v-if="!isLoading" :options="{ perPage: 50 }" :data="formsFilled"
                :columns="columns" @sorted="sortMe">
                <div slot="date" style="white-space:nowrap;" slot-scope="{row}">
                    <i v-if="role == 'admin' && row._id != null" @click="deleteFormFill(row._id)"
                        class="fal fa-trash trashentry"></i> {{
                            moment(row.date).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}
                </div>
                <div slot="user" style="white-space:nowrap;" slot-scope="{row}">
                    {{ row.user }}
                </div>
                <div slot="lights" slot-scope="{row}">
                    <div v-if="formDetails.name != 'Emergency Lighting Battery Testing (monthly)'">
                        <div v-for="(light, i) in row.lights" :key="i">
                            {{ light.name }} {{ light.fields[0].label }}:{{ light.fields[0].value }} {{
                            light.fields[1].label
                            }}:{{ light.fields[1].value }}
                        </div>
                    </div>
                    <div v-if="formDetails.name == 'Emergency Lighting Battery Testing (monthly)'">
                        <div v-for="(light, i) in row.lights" :key="i">
                            {{ light.name }} {{ light.fields[0].label }}:{{ light.fields[0].value }}
                        </div>

                    </div>

                </div>
                <div slot="detectors" slot-scope="{row}">
                    <div v-if="formDetails.name != 'Smoke Alarm Testing & Inspection Log'">
                        <div v-for="(light, i) in row.detectors" :key="i">
                            {{ light.name }} {{ light.fields[0].label }}:{{ light.fields[0].value }} {{
                            light.fields[1].label
                            }}:{{ light.fields[1].value }}
                        </div>
                    </div>
                    <div v-if="formDetails.name == 'Smoke Alarm Testing & Inspection Log'">
                        <div v-for="(light, i) in row.detectors" :key="i">
                            {{ light.name }} {{ light.fields[0].label }}:{{ light.fields[0].value }}
                        </div>

                    </div>

                </div>
                <div slot="signature" slot-scope="{row}">

                    <img v-if="row._id != null" style="height:45px;"
                        :src="`https://crm.theohanahawaii.com/api/static/forms/${row._id}/signature.jpg`" />
                </div>


                <div slot="id" slot-scope="{row}">


                    <div v-if="row.entries != null">

                        <v-btn small @click="tableExpand != row.id ? tableExpand = row.id : tableExpand = -1" block
                            text>Show
                            Entries</v-btn>

                        <!-- <div v-for="(key, k) in Object.keys(entry).filter((item)=>item != 'error')" :key="`k${k}`"> {{key}} : {{ entry[key] }}</div> -->
                        <v-expand-transition>
                            <div v-show="tableExpand == row.id" class="animated">
                                <table class="entry-table">
                                    <thead>
                                        <tr>
                                            <th><i class="fal fa-eye"></i></th>
                                            <th>User</th>
                                            <th v-for="(key, k) in Object.keys(row.entries[0]).filter((item) => item != 'error')"
                                                :key="`k${k}`">{{ key }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(entry, e) in row.entries" :key="`e${e}`">
                                            <td><a @click="$router.push(`/forms/${$route.params.id}/edit/${row.id}`)">View
                                                    Form</a></td>
                                            <td>{{ row.user }}</td>
                                            <td v-for="(key, k) in Object.keys(entry).filter((item) => item != 'error' && item != 'formId')"
                                                :key="`k${k}`">{{ entry[key] }}</td>
                                        </tr>

                                        <tr v-show="row.id != null && row.additionalForms != null"
                                            v-for="(entry, r) in row.additionalForms && getReduction(row.additionalForms)"
                                            :key="`r${r}`" style="white-space: nowrap;">
                                            <td><a
                                                    @click="$router.push(`/forms/${$route.params.id}/edit/${entry.formId}`)">View
                                                    Form</a></td>
                                            <td>{{ row.user }}</td>
                                            <td v-for="(key, k) in Object.keys(entry).filter((item) => item != 'error' && item != 'formId')"
                                                :key="`k${k}`">{{ entry[key] }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-expand-transition>
                    </div>

                    <!-- <div v-if="row.entries == null && row.id != null" >
                    
                    <v-btn small @click="tableExpand != row.id ? tableExpand = row.id : tableExpand = -1" block text>Show Entries</v-btn>
                    
                    
                    <v-expand-transition>
                        <div v-show="tableExpand == row.id" class="animated">
                    <table class="entry-table">
                    <thead>
                        <tr >
                        <th>View Form</th>
                        <th>User</th>
                        <th v-for="(key, k) in Object.keys(row).filter((item)=>formEntryColumns.includes(item))" :key="`k${k}`">{{key}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><a @click="$router.push(`/forms/${$route.params.id}/edit/${row.id}`)">View Form</a></td>
                            <td>{{ row.user }}</td>
                        <td v-for="(key, k) in Object.keys(row).filter((item)=>formEntryColumns.includes(item))" :key="`k${k}`">{{ row[key] }}</td>
                    </tr>
                    
                        <tr v-show="row.id != null && row.additionalForms != null" v-for="(entry, r) in row.additionalForms" :key="`r${r}`" style="white-space: nowrap;">
                            <td><a @click="$router.push(`/forms/${$route.params.id}/edit/${entry.id}`)">View Form</a></td>
                            <td>{{ row.user }}</td>
                            <td v-for="(key, k) in Object.keys(row).filter((item)=>formEntryColumns.includes(item))" :key="`k${k}`">{{ entry[key] }}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                    </v-expand-transition>
                </div> -->
                    <!-- <div v-if="row.id != null && row.entries == null">
                    <a v-if="row.id != null" @click="$router.push(`/forms/${$route.params.id}/edit/${row.id}`)" style="white-space: nowrap;">View Form</a><br>
                    <a v-show="row.id != null && row.additionalForms != null" v-for="(item, i) in row.additionalForms" @click="$router.push(`/forms/${$route.params.id}/edit/${item.id}`)" :key="i" style="white-space: nowrap;">View Form</a>
                </div> -->

                </div>

            </v-client-table>
        </div>

        <!-- IS INVENTORY -->

        <div v-if="formDetails != null && !isLoading && isInventory" class="form-container">

            <div class="f-name"><i class="fad fa-file"></i>{{ formDetails.name }} <v-btn
                    @click="$router.push(`/forms/${$route.params.id}/new`)" text depressed small dense dark
                    :color="appColor">Add&nbsp; <i style=" margin:0;" class="fal fa-plus"></i> </v-btn> <v-btn small
                    text :color="appColor" @click="exportPdf">export PDF</v-btn></div>
            <div class="f-table-total">{{ total }} Total Records </div>
            <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field :color="appColor" v-model="monthFormatted"
                        style="padding-top:0 !important; max-width:250px;"
                        @blur="month = parseDate(monthFormatted), monthFormatted = formatDate(month)" class="fixMe"
                        outlined hide-details small dense readonly v-bind="attrs" v-on="on"
                        @click:clear="month = null"></v-text-field>
                </template>
                <v-date-picker :color="appColor" type="month" v-model="month"
                    @change="($event) => { month = $event; monthFormatted = formatDate($event), dateMenu = false; getFormsFilled() }"></v-date-picker>
            </v-menu><br>

            <v-client-table ref="my-table" v-if="!isLoading" :options="{ perPage: 50 }" :data="formsFilled"
                :columns="columns" @sorted="sortMe">
                <div slot="date" style="white-space: nowrap;" slot-scope="{row}">
                    {{ moment(row.date).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}
                </div>
                <div slot="date_stored" style="white-space: nowrap;" slot-scope="{row}">

                    <i v-if="role == 'admin'" @click="deleteFormEntry(row.formId, row.entryIndex)"
                        class="fal fa-trash trashentry"></i> <span
                        v-if="row.date_stored != '' && row.date_stored != null">{{
                            moment(row.date_stored).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }} </span>
                </div>

                <div slot="expiration_date" style="white-space: nowrap;" slot-scope="{row}">

                    <span v-if="row.expiration_date != '' && row.expiration_date != null">{{
                        moment(row.expiration_date).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}</span>
                </div>
                <span slot="h__narcan_id" style="white-space: nowrap;">
                    Narcan ID
                </span>
                <span slot="h__expiration_date" style="white-space: nowrap;">
                    Expiration Date
                </span>
                <span slot="h__date_stored" style="white-space: nowrap;">
                    Date Stored
                </span>
                <span slot="h__date_removed" style="white-space: nowrap;">
                    Date Removed
                </span>


                <span slot="h__date_removed_signature" style="white-space: nowrap;">
                    Date Removed Signature
                </span>
                <div slot="date_removed_signature" slot-scope="{row}">
                    <img v-if="row._id != null" style="height:45px;"
                        :src="`https://crm.theohanahawaii.com/api/static/forms/${row._id}/date_removed_signature.jpg`" />
                </div>
                <div slot="date_removed" style="white-space: nowrap;" slot-scope="{row}">
                    <v-btn v-if="row.date_removed == ''" x-small @click="sigOpen = true, activeRow = row" depressed
                        :color="appColor" dark>Remove Narcan</v-btn>&nbsp;&nbsp;
                    <span v-if="row.date_removed != '' && row.date_removed != null">{{
                        moment(row.date_removed).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}</span>
                </div>

                <div slot="id" slot-scope="{row}">
                    <a v-if="row.id != null" @click="$router.push(`/forms/${$route.params.id}/edit/${row.id}`)"
                        style="white-space: nowrap;"><i class="fal fa-eye"></i></a>
                </div>
                <div slot="signature" slot-scope="{row}">

                    <img v-if="row._id != null" style="height:45px;"
                        :src="`https://crm.theohanahawaii.com/api/static/forms/${row._id}/signature.jpg`" />
                </div>


            </v-client-table>
        </div>

        <!-- IS Refrigerator log     -->

        <div v-if="formDetails != null && !isLoading && isRunning && formDetails.name == 'Refrigerator/Freezer Temp Log'"
            class="form-container">

            <div class="f-name"><i class="fad fa-file"></i>{{ formDetails.name }} <v-btn
                    @click="$router.push(`/forms/${$route.params.id}/new`)" text depressed small dense dark
                    :color="appColor">Add&nbsp; <i style=" margin:0;" class="fal fa-plus"></i> </v-btn> <v-btn small
                    text :color="appColor" @click="exportPdf">export PDF</v-btn></div>
            <div class="f-table-total">{{ total }} Total Records </div>
            <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field :color="appColor" v-model="monthFormatted"
                        style="padding-top:0 !important; max-width:250px;"
                        @blur="month = parseDate(monthFormatted), monthFormatted = formatDate(month)" class="fixMe"
                        outlined hide-details small dense readonly v-bind="attrs" v-on="on"
                        @click:clear="month = null"></v-text-field>
                </template>
                <v-date-picker :color="appColor" type="month" v-model="month"
                    @change="($event) => { month = $event; monthFormatted = formatDate($event), dateMenu = false; getFormsFilled() }"></v-date-picker>
            </v-menu><br>
            <div :key="`${t}t`"
                v-for="(table, t) in formDetails.fields[formDetails.fields.findIndex((it) => it.prop == 'fridge_id')].options">
                <div style="font-weight: bold; font-size:12pt; margin:15px 0px;">{{ table.text }} <v-btn small text
                        :color="appColor" @click="exportFridge(table)">Export PDF</v-btn></div>
                <v-client-table ref="my-table" v-show="!isLoading" :options="{ perPage: 50 }"
                    :data="formsFilled.filter((item) => item.fridge_id == table.value)" :columns="formEntryColumns"
                    @sorted="sortMe">
                    <div style="white-space:nowrap; " slot="date" slot-scope="{row}">
                        <i v-if="role == 'admin'" @click="deleteFormFill(row._id)" class="fal fa-trash trashentry"></i>
                        {{
                            moment(row.date).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}
                    </div>
                    <div style="white-space:nowrap; " slot="user" slot-scope="{row}">
                        {{ row.user }}
                    </div>


                    <div slot="lights" slot-scope="{row}">
                        <div v-for="(light, i) in row.lights" :key="i">
                            {{ light.name }} {{ light.fields[0].label }}:{{ light.fields[0].value }} {{
                            light.fields[1].label
                            }}:{{ light.fields[1].value }}
                        </div>
                    </div>
                    <div slot="signature" slot-scope="{row}">

                        <img v-if="row._id != null" style="height:45px;"
                            :src="`https://crm.theohanahawaii.com/api/static/forms/${row._id}/signature.jpg`" />
                    </div>


                </v-client-table>
            </div>


        </div>

        <!-- IS Medical Equipment Log -->

        <div v-if="formDetails != null && !isLoading && isRunning && formDetails.name == 'Medical Equipment Log'"
            class="form-container">

            <div class="f-name"><i class="fad fa-file"></i>{{ formDetails.name }} <v-btn
                    @click="$router.push(`/forms/${$route.params.id}/new`)" text depressed small dense dark
                    :color="appColor">Add&nbsp; <i style=" margin:0;" class="fal fa-plus"></i> </v-btn> <v-btn small
                    text :color="appColor" @click="exportPdf">export PDF</v-btn></div>
            <div class="f-table-total">{{ total }} Total Records </div>
            <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field :color="appColor" v-model="monthFormatted"
                        style="padding-top:0 !important; max-width:250px;"
                        @blur="month = parseDate(monthFormatted), monthFormatted = formatDate(month)" class="fixMe"
                        outlined hide-details small dense readonly v-bind="attrs" v-on="on"
                        @click:clear="month = null"></v-text-field>
                </template>
                <v-date-picker :color="appColor" type="month" v-model="month"
                    @change="($event) => { month = $event; monthFormatted = formatDate($event), dateMenu = false; getFormsFilled() }"></v-date-picker>
            </v-menu><br>
            <div :key="`${t}t`"
                v-for="(table, t) in formDetails.layout[1].template[formDetails.layout[1].template.findIndex((it) => it.prop == 'equipment_type')].options">
                <div style="font-weight: bold; font-size:12pt; margin:15px 0px;">{{ table.text }}</div>
                <v-client-table ref="my-table" v-show="!isLoading" :options="{ perPage: 50 }"
                    :data="formsFilled.filter((item) => item.equipment_type == table.value)" :columns="formEntryColumns"
                    @sorted="sortMe">
                    <div slot="signature" slot-scope="{row}">

                        <img v-if="row._id != null" style="height:45px;"
                            :src="`https://crm.theohanahawaii.com/api/static/forms/${row._id}/signature.jpg`" />
                    </div>
                    <div slot="lights" slot-scope="{row}">
                        <div v-for="(light, i) in row.lights" :key="i">
                            {{ light.name }} {{ light.fields[0].label }}:{{ light.fields[0].value }} {{
                            light.fields[1].label
                            }}:{{ light.fields[1].value }}
                        </div>
                    </div>
                    <div style="white-space:nowrap; " slot="date" slot-scope="{row}">
                        <i v-if="role == 'admin'" @click="deleteFormFill(row._id)" class="fal fa-trash trashentry"></i>
                        {{
                            moment(row.date).tz('America/Los_Angeles').utc().format('MM/DD/YYYY') }}
                    </div>
                    <div style="white-space:nowrap; " slot="user" slot-scope="{row}">
                        {{ row.user }}
                    </div>




                </v-client-table>
            </div>


        </div>
    </div>
</template>
<script>
import axios from "axios";
import { sign } from "crypto";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import vueSignature from "vue-signature";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
export default {
    name: 'form',
    components: {
        vueSignature
    },
    data() {
        return {
            dateMenu: false,
            month: moment().tz('America/Los_Angeles').utc().startOf('M').format('YYYY-MM-DD'),
            monthFormatted: this.formatDate((new Date(moment().tz('America/Los_Angeles').utc().startOf('M').format('YYYY-MM-DD'))).toISOString().substr(0, 10)),
            year: moment().utc().format('YYYY'),
            moment,
            isLoading: true,
            formDetails: null,
            formsFilled: [],
            filters: [],
            filterSort: 'dec',
            isRunning: false,
            isInventory: false,
            total: 0,
            deletingFormEntryLoading: false,
            allForms: [],
            //refridge log
            freezerName: '',
            breathalyzerName: '',
            tableExpand: -1,
            sigOpen: false,
            option: { penColor: "rgb(0, 0, 0)", backgroundColor: "rgb(255,255,255)" },
            sigError: false,
            signLoading: false,
            activeRow: null
        }
    },
    async mounted() {
        let q = this.$route.query;
        if (q.sort == null) {
            this.$router.push({
                query: { ...this.$route.query, filter: 'date', sort: 'dec' },
            });
        }
        if (q.term != null) {
            this.term = q.term;
        }

        await this.getFormDetails();
        await this.getFormsFilled();
    },
    methods: {
        exportFridge(table) {

            let data = this.formsFilled.filter((item) => item.fridge_id == table.value)
            const doc = new jsPDF();
            let sigColumns = [];
            let sigColumnNames = []
            doc.text(this.formDetails.name + ' ' + table.value, 10, 10);
            doc.setFontSize(9);
            if (this.formDetails.name == 'Hand Hygiene Monitoring Log') {
                doc.text(`Hand Hygiene Compliance Percentage: ${this.handHygieneCompliancePercent}`, 10, 20,);
            }

            // let table = this.$refs['my-table'].$el.childNodes[1].childNodes[0]
            // table.id = 't-able';

            // autoTable(doc, { html: '#t-able' })

            let col = this.columns;
            if (this.formDetails.name == 'Refrigerator/Freezer Temp Log' ||
                this.formDetails.name == 'Medical Equipment Log') {
                col = this.formEntryColumns;
            }
            let colsF = col.map((t) => { return t.replaceAll('_', ' ') });

            autoTable(doc, {
                theme: 'plain',
                styles: { fontSize: 8 },
                margin: { top: this.formDetails.name == 'Hand Hygiene Monitoring Log' ? 30 : 20 },
                head: [col],
                body: data.map((item) => {
                    let keys = Object.keys(item);
                    keys.filter((item2) => col.includes(item2));
                    let newObj = {};
                    for (let i = 0; i < Object.keys(item).length; i++) {
                        if (keys.includes(Object.keys(item)[i])) {
                            newObj[Object.keys(item)[i]] = item[Object.keys(item)[i]]
                        } else {

                        }
                    }
                    return col.map((item) => {
                        if (item.includes('date')) {
                            return moment(newObj[item]).tz('America/Los_Angeles').utc().format('MM/DD/YYYY')
                        } else {
                            if (item == 'lights') {

                                return newObj[item].map((itit) => {
                                    let passed = itit.fields[itit.fields.findIndex((tt) => tt.prop == 'passed')].value
                                    let replaced = this.formDetails.name != 'Emergency Lighting Battery Testing (monthly)' ? itit.fields[itit.fields.findIndex((tt) => tt.prop == 'replaced')].value : false;
                                    return `${itit.name} Pass:${passed ? 'Y' : 'N'} Replaced:${replaced ? 'Y' : 'N'}`
                                }).join("\n")
                            } else if (item == 'detectors') {
                                return newObj[item].map((itit) => {
                                    let passed = itit.fields[itit.fields.findIndex((tt) => tt.prop == 'passed')].value
                                    return `${itit.name} Pass:${passed ? 'Y' : 'N'}`
                                }).join("\n")
                            } else {
                                return newObj[item]
                            }

                        }


                    })

                }),
                didDrawCell: (data) => {
                    if (data.cell.section == 'head' && data.cell.raw.includes('signa')) {
                        sigColumns.push(data.column.index);
                        sigColumnNames.push(data.cell.raw);

                    }
                    if (data.cell.section == 'body' && sigColumns.includes(data.column.index)) {
                        let columnName = sigColumnNames[sigColumns.findIndex((it) => it == data.column.index)];
                        let img = new Image();
                        img.src = `https://crm.theohanahawaii.com/api/static/forms/${this.formsFilled[data.row.index]._id}/${columnName}.jpg`
                        img.width = 100;
                        var dim = data.cell.height;
                        var textPos = data.cell;
                        if (this.formsFilled[data.row.index]._id != null) doc.addImage(img.src, textPos.x, textPos.y, data.cell.width, dim);
                    }
                }
            })
            doc.save(`${this.formDetails.name}_${table.value}.pdf`);
        },
        async sign() {
            this.signLoading = true;
            let errorCheck = false;
            this.sigError = false;
            if (this.$refs.signature.isEmpty()) {
                errorCheck = true;
                this.sigError = true;
            }
            if (errorCheck) {
                this.signLoading = false;
                console.log('HAS ERROR')
                return;
            }
            let formData = new FormData();
            const dataURLtoFile = (dataurl, filename) => {
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[arr.length - 1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, { type: mime });
            }
            await axios.put(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/editFormFill/${this.activeRow._id}`, { removingUser: this.user.id, date_removed: new Date() })
            let jpeg = this.$refs.signature.save("image/jpeg");
            formData.append('sig', dataURLtoFile(jpeg), 'date_removed_signature.jpg');
            await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/upload_signature/${this.activeRow._id}`, formData);
            this.signLoading = false;
            this.sigOpen = false;
            this.getFormsFilled();
        },
        clear() {
            this.$refs.signature.clear();
        },
        exportPdf() {
            // Default export is a4 paper, portrait, using millimeters for units
            const doc = new jsPDF();
            let sigColumns = [];
            let sigColumnNames = []
            doc.text(this.formDetails.name, 10, 10);
            doc.setFontSize(9);
            if (this.formDetails.name == 'Hand Hygiene Monitoring Log') {
                doc.text(`Hand Hygiene Compliance Percentage: ${this.handHygieneCompliancePercent}`, 10, 20,);
            }

            // let table = this.$refs['my-table'].$el.childNodes[1].childNodes[0]
            // table.id = 't-able';

            // autoTable(doc, { html: '#t-able' })

            let col = this.columns;
            if (this.formDetails.name == 'Refrigerator/Freezer Temp Log' ||
                this.formDetails.name == 'Medical Equipment Log') {
                col = this.formEntryColumns;
            }
            let colsF = col.map((t) => { return t.replaceAll('_', ' ') });

            autoTable(doc, {
                theme: 'plain',
                styles: { fontSize: 8 },
                margin: { top: this.formDetails.name == 'Hand Hygiene Monitoring Log' ? 30 : 20 },
                head: [col],
                body: this.formsFilled.map((item) => {
                    let keys = Object.keys(item);
                    keys.filter((item2) => col.includes(item2));
                    let newObj = {};
                    for (let i = 0; i < Object.keys(item).length; i++) {
                        if (keys.includes(Object.keys(item)[i])) {
                            newObj[Object.keys(item)[i]] = item[Object.keys(item)[i]]
                        } else {

                        }
                    }
                    return col.map((item) => {
                        if (item.includes('date')) {
                            return moment(newObj[item]).tz('America/Los_Angeles').utc().format('MM/DD/YYYY')
                        } else {
                            if (item == 'lights') {

                                return newObj[item].map((itit) => {
                                    let passed = itit.fields[itit.fields.findIndex((tt) => tt.prop == 'passed')].value
                                    let replaced = this.formDetails.name != 'Emergency Lighting Battery Testing (monthly)' ? itit.fields[itit.fields.findIndex((tt) => tt.prop == 'replaced')].value : false;
                                    return `${itit.name} Pass:${passed ? 'Y' : 'N'} Replaced:${replaced ? 'Y' : 'N'}`
                                }).join("\n")
                            } else if (item == 'detectors') {
                                return newObj[item].map((itit) => {
                                    let passed = itit.fields[itit.fields.findIndex((tt) => tt.prop == 'passed')].value
                                    return `${itit.name} Pass:${passed ? 'Y' : 'N'}`
                                }).join("\n")
                            } else {
                                return newObj[item]
                            }

                        }


                    })

                }),
                didDrawCell: (data) => {
                    if (data.cell.section == 'head' && data.cell.raw.includes('signa')) {
                        sigColumns.push(data.column.index);
                        sigColumnNames.push(data.cell.raw);

                    }
                    if (data.cell.section == 'body' && sigColumns.includes(data.column.index)) {
                        let columnName = sigColumnNames[sigColumns.findIndex((it) => it == data.column.index)];
                        let img = new Image();
                        img.src = `https://crm.theohanahawaii.com/api/static/forms/${this.formsFilled[data.row.index]._id}/${columnName}.jpg`
                        img.width = 100;
                        var dim = data.cell.height;
                        var textPos = data.cell;
                        if (this.formsFilled[data.row.index]._id != null) doc.addImage(img.src, textPos.x, textPos.y, data.cell.width, dim);
                    }
                }
            })
            doc.save(`${this.formDetails.name}.pdf`);
        },
        getReduction(items) {
            let arr = items.reduce((prev, curr) => {
                return prev.entries && [...prev.entries.map((item) => ({ ...item, formId: prev._id })), ...curr.entries.map((item) => ({ ...item, formId: curr._id }))]
            }, items.length == 1 ? { entries: [] } : null)
            return arr
        },
        async updateForm() {
            try {
                let post = this.formDetails;
                await axios.put(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/edit/${this.$route.params.id}`, post)
            } catch (e) {
                console.log(e);
            }
        },
        async deleteFormFill(id) {
            this.deletingFormEntryLoading = true
            try {
                let { data } = await axios.delete(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/delete_form_fill/${id}`);
                this.deletingFormEntryLoading = false;
                this.getFormsFilled();
            } catch (e) {
                window.alert('There was a problem deleting entry');
                this.deletingFormEntryLoading = false;
            }
        },
        async deleteFormEntry(formId, entryIndex) {

            this.deletingFormEntryLoading = true;
            let form = this.allForms.filter((item) => item._id == formId);
            if (!form.length) return;
            form[0].entries.splice(entryIndex, 1);
            try {
                let { data } = await axios.put(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/editFormFill/${formId}`, form[0]);
                this.deletingFormEntryLoading = false;
                this.getFormsFilled();
            } catch (e) {
                window.alert('There was a problem deleting entry');
                this.deletingFormEntryLoading = false;
            }
        },
        formatDate(date) {
            if (!date) return null
            const [year, month] = date.split('-')
            return `${month}/${year}`
        },
        parseDate(date) {
            console.log(date);
            if (!date) return null
            const [month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-01`
        },
        sortMe(eve) {
            let f = "asc";
            if (this.$route.query.sort == "asc") {
                f = "dec";
            } else {
                f = "asc";
            }
            this.columnSort = eve.column;
            this.$router.push({
                query: { ...this.$route.query, filter: eve.column, sort: f },
            });

            this.getFormsFilled();
        },
        async getFormDetails() {

            try {
                let { data } = await axios.get(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_form_details/${this.$route.params.id}`)
                this.formDetails = data.result;
                this.isRunning = data.result.type == 'running';
                // this.isInventory = data.result.name == 'Narcan Log'
            } catch (e) {
                console.log(e);
            }
        },

        async getFormsFilled() {
            this.isLoading = true;
            this.lastTerm = this.term;

            if (this.isRunning || this.formDetails.name == 'Hand Hygiene Monitoring Log') {
                this.filters = [
                    {
                        name: 'date',
                        type: 'date',
                        operator: 'after',
                        startDate: moment(this.month).tz('America/Los_Angeles').utc().startOf('M').toDate()
                    },
                    {
                        name: 'date',
                        type: 'date',
                        operator: 'before',
                        startDate: moment(this.month).tz('America/Los_Angeles').utc().endOf('M').toDate()
                    }
                ]
            }

            if (this.formDetails.name == 'Emergency Lighting Battery Testing (monthly)') {
                this.filters = [
                    {
                        name: 'date',
                        type: 'date',
                        operator: 'after',
                        startDate: moment().set('year', this.year).tz('America/Los_Angeles').utc().startOf('Y').toDate()
                    },
                    {
                        name: 'date',
                        type: 'date',
                        operator: 'before',
                        startDate: moment().set('year', this.year).tz('America/Los_Angeles').utc().endOf('Y').toDate()
                    }
                ]
            }

            if (this.isInventory) {
                this.filters = [
                    {
                        name: 'date',
                        type: 'date',
                        operator: 'after',
                        startDate: moment(this.month).tz('America/Los_Angeles').utc().startOf('M').toDate()
                    },
                    {
                        name: 'date',
                        type: 'date',
                        operator: 'before',
                        startDate: moment(this.month).tz('America/Los_Angeles').utc().endOf('M').toDate()
                    }
                ]
            }
            let post = {
                field: this.searchField,
                term: this.term,
                page: this.page,
                sortName: this.$route.query.filter,
                sort: this.$route.query.sort,
                filters: this.filters,
                type: "master",
            };
            await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_forms_filled/${this.$route.params.id}`, post).then(async (res) => {
                let forms = res.data.items;
                this.allForms = res.data.items;
                this.formsFilled = res.data.items;
                let entries = [];
                for (let i = 0; i < forms.length; i++) {

                    entries = forms[i].entries && entries.concat(forms[i].entries.map((item, e) => {
                        let keys = Object.keys(item);
                        for (let i = 0; i < keys.length; i++) {
                            if (typeof item[keys[i]] == 'boolean') {
                                item[keys[i]] = item[keys[i]] ? 'Yes' : 'No'
                            }
                        }
                        return {

                            ...item,
                            date: forms[i].date,
                            status: forms[i].status,
                            entryIndex: Number(e),
                            user: `${forms[i].user[0].first_name} ${forms[i].user[0].last_name}`,
                            _id: forms[i]._id,
                            complete: forms[i].complete || false,
                            formId: forms[i]._id
                        }
                    }))
                }
                if (this.isRunning) {
                    this.formsFilled = [];
                    for (let i = 0; i < Number(moment(this.month).tz('America/Los_Angeles').utc().daysInMonth()); i++) {

                        this.formsFilled[i] = {
                            date: moment(this.month).tz('America/Los_Angeles').utc().startOf('M').set("date", i + 1).format('MM/DD/YYYY'),
                            id: null,
                            user: null,
                            facility: null,
                            additionalForms: []
                        }
                    }
                    for (let f = 0; f < forms.length; f++) {
                        let dateNumber = Number(moment(forms[f].date).utc().format('DD'))
                        console.log(forms[f].date, dateNumber);
                        if (this.formsFilled[dateNumber - 1] && this.formsFilled[dateNumber - 1].id == null) {
                            this.formsFilled[dateNumber - 1] = {
                                ...forms[f],
                                date: this.formsFilled[dateNumber - 1].date,
                                user: forms[f].user[0] && forms[f].user[0].first_name + ' ' + forms[f].user[0].last_name,
                                id: forms[f]._id
                            }
                        } else {
                            if (dateNumber != null && this.formsFilled[dateNumber - 1]) {
                                this.formsFilled[dateNumber - 1] = {
                                    ...this.formsFilled[dateNumber - 1],
                                    //...forms[f],
                                    additionalForms: this.formsFilled[dateNumber - 1] && this.formsFilled[dateNumber - 1].additionalForms != null ? [...this.formsFilled[dateNumber - 1].additionalForms, forms[f]] : [{ ...forms[f], id: forms[f]._id }],
                                    // date:this.formsFilled[dateNumber].date,
                                    // user: forms[f].user[0] && forms[f].user[0].first_name + ' ' + forms[f].user[0].last_name,
                                    // id: forms[f]._id
                                }
                            }

                        }


                    }
                } else if (this.isInventory) {
                    this.formsFilled = [];
                    // for(let i = 0; i < Number(moment().tz('America/Los_Angeles').utc().daysInMonth()); i++){

                    //     this.formsFilled[i] = {
                    //         date:moment(`${moment(this.month).tz('America/Los_Angeles').utc().tz('America/Los_Angeles').utc().set("date",i+1).format('MM-DD-YYYY')}`),
                    //         id:null,
                    //         user:null,
                    //         facility:null
                    //     }
                    // }
                    for (let f = 0; f < entries.length; f++) {


                        this.formsFilled[f] = {
                            ...entries[f],
                            // user: entries[f].user[0] && entries[f].user[0].first_name + ' ' + entries[f].user[0].last_name,
                            // id: entries[f]._id
                        }
                    }
                    this.total = entries.length;
                } else {

                    this.formsFilled = this.formsFilled.map((item) => {
                        return {
                            ...item,
                            status: item.status || 'new',
                            user: item.user[0] && item.user[0].first_name + ' ' + item.user[0].last_name,
                            id: item._id
                        }
                    })
                    this.total = res.data.total;
                }
                if (
                    this.formDetails.name == 'Refrigerator/Freezer Temp Log'

                ) {

                    this.formsFilled = res.data.items.map((item) => {
                        return {
                            ...item,

                            user: item.user[0] && item.user[0].first_name + ' ' + item.user[0].last_name,
                            id: item._id
                        }
                    })
                }
                if (this.formDetails.name == 'Medical Equipment Log') {
                    let tempFormsFilled = res.data.items.map((item) => {
                        return {
                            ...item,

                            user: item.user[0] && item.user[0].first_name + ' ' + item.user[0].last_name,
                            id: item._id
                        }
                    })
                    let ff = [];
                    for (let i = 0; i < tempFormsFilled.length; i++) {
                        for (let p = 0; p < tempFormsFilled[i].entries.length; p++) {
                            let obj = {
                                ...tempFormsFilled[i].entries[p],
                                date: tempFormsFilled[i].date,
                                user: tempFormsFilled[i].user,
                                _id: tempFormsFilled[i]._id,
                            }
                            ff.push(obj);
                        }
                    }
                    this.formsFilled = ff;
                }
                if (this.formDetails.name == 'Medication/Substance Disposal' || this.formDetails.name == 'Breathalyzer') {
                    this.formsFilled = entries;
                    this.total = entries.length;
                    if (this.formDetails.name == 'Breathalyzer' && this.total >= 200) {
                        let post = {
                            date: new Date(),
                            facility: this.facility,
                            message: 'Breathalyzer has reached 200 tests.'
                        }
                        axios.post(`/api/reminders/add`, post);
                    }
                }

                if (this.filterSort == 'dec') {
                    this.formsFilled = this.formsFilled;
                } else {
                    this.formsFilled = this.formsFilled.reverse();
                }
                this.isLoading = false;

            });
        },
    },
    computed: {
        appColor() {
            if (this.facility == null) return '#4db7c9';
            return this.facility == 'oasis' ? '#a6855d' : '#4db7c9';
        },
        ...mapGetters({
            user: 'AUTH_USER',
            facility: 'FACILITY'
        }),
        handHygieneCompliancePercent() {
            if (this.formDetails.name == 'Hand Hygiene Monitoring Log') {
                let t = this.formsFilled.length;
                let good = 0;

                for (let i = 0; i < this.formsFilled.length; i++) {
                    if (this.formsFilled[i].hh_action.indexOf('Approp') > -1) {
                        good += 1;
                    }
                }
                return Math.round(good / t * 100) + '%'
            }
        },
        show() {
            if (this.formDetails.name == 'Refrigerator/Freezer Temp Log') {
                return false;
            } else if (this.formDetails.name == 'Medical Equipment Log') {

                return false;
            } else {
                return true;
            }
        },
        formEntryColumns() {
            if (this.formDetails.name == 'Refrigerator/Freezer Temp Log') {
                return [
                    'date',
                    'time',
                    'user',
                    'fridge_id',
                    'temp',
                    'action',
                    "signature"
                ]
            }
            if (this.formDetails.name == 'Medical Equipment Log') {
                return [
                    'date',
                    'user',
                    'equipment_type',
                    'replaced',
                    'comments',
                    'signature'
                ]
            }
            if (this.formDetails.name == 'Sanitization Log') {
                return [
                    'concentration',
                    'action',
                    "signature"
                ]
            }
            if (this.formDetails.name == 'Sharp/Needle Log') {
                return [
                    'date',
                    'disposed',
                    "signature"
                ]
            }
        },
        role() {
            return this.user.role;
        },
        columns() {
            if (this.formDetails.name == 'Hand Hygiene Monitoring Log') {
                return [
                    "date",
                    "user",
                    "observer_name",
                    "hh_moment",
                    "hh_action",
                    "hh_action_function_code",
                    'signature'
                ]
            }
            if (this.formDetails.name == 'Work Order') {
                return [
                    "_id",
                    "date",
                    "user",
                    "status",
                    'signature'
                ]
            }
            if (this.formDetails.name == 'Smoke Alarm Testing & Inspection Log') {
                return [
                    "date",
                    "user",
                    "detectors",
                    'signature'
                ]
            }
            if (this.formDetails.name == 'Emergency Lighting Battery Testing & Replacement') {
                return [
                    "date",
                    "user",
                    "lights",
                    "comments",
                    'signature'
                ]
            }
            if (this.formDetails.name == 'Sharp/Needle Log') {
                return [
                    'date',
                    'disposed',
                    "signature"
                ]
            }
            if (this.formDetails.name == 'Emergency Lighting Battery Testing (monthly)') {
                return [
                    "date",
                    "user",
                    "lights",
                    "comments",
                    'signature'
                ]
            }
            if (this.formDetails.name == 'Breathalyzer') {
                return [
                    "date",
                    "name",
                    "user",
                    "tested",
                    "count",
                    'signature'
                ]
            }
            if (this.formDetails.name == 'Medication/Substance Disposal') {
                return [
                    "_id",
                    "date",
                    "user",
                    "client_name",
                    "item_for_disposal",
                    "dosage",
                    "quantity",
                    "date_disposed",
                    'signature',
                    'signature2',
                    'signature3'
                ]
            }
            if (this.formDetails.name == 'Narcan Log') {
                return [
                    "date",
                    "user",
                    "date_stored",
                    "date_removed",
                    "narcan_id",
                    "expiration_date",
                    "location",
                    "signature",
                    "date_removed_signature",
                ]
            }
            if (this.formDetails.name == 'Sanitization Log') {
                return [
                    "date",
                    "user",
                    "concentration",
                    "action",
                    "signature",

                ]
            }
            if (this.formDetails.name == 'Vehicle Inspection Log') {
                return [
                    "date",
                    "id",
                    "user",
                    "vehicle",
                    "mileage",
                    "signature",

                ]
            }
            if (this.formDetails.name == 'Grievance Form') {
                return [
                    "date",
                    "id",
                    "user",
                    "followed_up"
                ]
            }
            if (this.formDetails.name == 'Epi-pen Log') {
                return [
                    "date",
                    "user",
                    "expiration_date",
                    "used",
                    "serial_number",
                    "signature"
                ]
            }
            return [
                "date",
                "user",
                "id"

            ]
        }
    }
}
</script>
<style lang="scss">
.followup-tag {
    background: #ff0000;

    &.y {
        background: #44aa00;
    }

    color:#fff;
    padding:5px 15px;
    display:inline-block;
    border-radius: 100px;
}

@import "@/assets/scss.scss";

.form-container {
    position: relative;
    padding: 15px;
    max-width: 100% !important;

    .f-name {
        font-size: 15pt;

        font-weight: bold;

        i {
            color: var(--app-color);
            margin-right: 10px;
        }

    }

    text-align: left;


}

.f-table-total {
    margin: 15px 0px;
    color: #000;
    font-weight: bold;
}

.trashentry {
    padding: 0px 5px;
    opacity: .3;
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
        opacity: 1;

    }
}

.admin-edit-container {
    padding: 15px;

}

.add-option-input {
    height: 35px;
    display: flex;
    align-items: center;
    border: solid 1px #eaeaea;
    border-radius: 4px;
    padding: 10px;

    &:focus {
        border: solid 1px #000;
    }

    margin-bottom:10px;
    margin-top:10px;
}

.entry-table {
    tbody {
        tr {
            td {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
                overflow: hidden;
            }
        }
    }
}
.status-tag{
    padding:5px 10px;
    color:#fff;
    font-size:.8em;
    border-radius:100px;
    display:inline-block;
    &.new{
        background:#3080e8;
    }
    &.in_progress{
        background:#ff9900;
    }
    &.completed{
        background:#44aa00;
    }
}
.table tbody tr td{
    overflow: hidden;
}
</style>