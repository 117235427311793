<template>
    <div class="carrd">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name:'text-input',
    props:{
        color:{
            type:String,
            default:'#ff9900'
        },
        block:{
            type:Boolean,
            default:true
        },
        small:{
            type:Boolean,
            default:false
        },
        
        large:{
            type:Boolean,
            default:false
        },
        'x-large':{
            type:Boolean,
            default:false
        },
        fab:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style lang="scss">
    .carrd{
        background:#fff;
        border-radius: 10px;
    }
</style>