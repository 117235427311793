<template>
    <div class="d-select" :class="{'dark-d':dark}">
        <label class="filled">{{label}}</label>
        <select @input="$emit('input', $event.target.value)" :value="value">
            <option v-for="(item,i) in items" :key="i" :value="item">{{item}}</option>
        </select>
        <i class="fa fa-caret-down"></i>
        <div class="bottom-line"></div>
    </div>
</template>
<script>
export default {
    name:'select',
    props:{
        color:{
            type:String,
            default:'#3e80e3'
        },
        dark:{
            type:Boolean,
            default:false
        },
        value:{
            type:String,
            default:''
        },
        label:{
            type:String,
            default:''
        },
        
        items:{
            type:Array,
            default:()=>[]
        },
        'x-large':{
            type:Boolean,
            default:false
        },
        fab:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style lang="scss">
    .d-select{
        position:relative;
        margin-top:25px;
        width:100%;
        display:flex;
        align-items: center;
       
        border-radius: 10px;
        background:#ffffff20;
        i{
            margin-left:auto;
            font-size:10pt;
            color:#00000099;
        }
        padding:0;
        select{
            color:#000;
            padding:10px 5px;
            width:100%;
            font-size:12pt;
            &:focus{
                outline:none;
            }
        }
        &:focus-within{
            label{
                max-width:153%;
                transform:translateY(-26px) translateX(0px) scale(.7,.7);
            }
            .bottom-line{
                background:#000;
            }
        }
        .bottom-line{
            height:1px;
            background:transparent;
            width:100%;
            
            position:absolute;
            bottom:0;
            left:0;
        }
        .filled{
                max-width:153%;
                transform:translateY(-26px) translateX(0px) scale(.7,.7) !important;
            }
        label{
            max-width:90%;
            transform-origin: top left;
            transition:all .3s ease;
            width:200%;
            pointer-events: none;
            position:absolute;
            left:10px;
            top:10px;
            overflow: hidden;
            padding:0px;
            font-size:12pt;
            white-space: nowrap;
            text-overflow: ellipsis;
            color:#000;
        }
        &.dark-d{
            label{
                color:#fff;
            }
            input{
                color:#fff;
            }
            select{
                color:#fff;
            }
            option{
                color:#000;
            }
            .bottom-line{
                background:transparent;
            }
        &:focus-within{
            label{
                max-width:153%;
                transform:translateY(-26px) translateX(0px) scale(.7,.7);
            }
            .bottom-line{
                background:transparent;
            }
        }
        }
    }
</style>