<template>
    <button :type="type" @click="$emit('click')" class="btn-b" :class="{'small-d':small,'disabled-d':disabled,'large-d':large,'block-d':block,'dark-d':dark, 'fab':fab}" :style="`background:${color};`">
        <slot></slot>
    </button>
</template>
<script>
export default {
    name:'button',
    props:{
        type:{
            type:String,
            default:'submit'
        },
        color:{
            type:String,
            default:'#002c54'
        },
        block:{
            type:Boolean,
            default:false
        },
        small:{
            type:Boolean,
            default:false
        },
        
        large:{
            type:Boolean,
            default:false
        },
        dark:{
            type:Boolean,
            default:false
        },
       
        fab:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        },
        disabled:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss.scss";
    .btn-b{
        outline:none;
        border:none;
        text-align: center;
        padding:15px 35px;
        margin-top:40px;
        display:inline-block;
        font-size:13pt;
        font-family: Comfortaa !important;
        text-transform: uppercase;
        letter-spacing: 2px;
        border-radius: 5px;
        transition:all .1s ease;
        box-sizing: border-box;
        font-weight: lighter;
        white-space:nowrap;
        @include media-query($small) {
                white-space:unset !important;
            }
        &:hover{
            cursor: pointer;
            box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);
        }
        &:active{
            box-shadow: 0px 0px 0px 4px rgba(0,0,0,0.3);
        }
        
        color:#fff;
    }
    .fab{
        height:50px;
        width:50px;
        border-radius: 50px;
        align-items: center;
        justify-content: center;
        padding:0 !important;
        display:inline-flex;
    }
    .small-d{
        padding:10px 15px;
        font-size:12pt;
    }
    .large-d{
        padding:15px 30px;
        font-size:17pt;
    }
    .x-large{
        padding:20px 40px;
        font-size:20pt;
    }
    .block-d{
        padding:20px 40px;
        width:100%;
        text-align: center;
    }
    .disabled-d{
        pointer-events: none;
        background:#dfdfdf !important;
        color:#00000030;
        &.dark-d{
            background:#00000030 !important;
            color:#ffffff80;
        }
    }
</style>