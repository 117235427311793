<template>
    <div style="display:flex; align-items: center; height:calc(100vh - 70px); width:100%; justify-content: center;" class="inner-stage" >
        <div v-if="facility == 'oasis'" class="welcome-message">
            <!-- <i class="fad fa-hand-peace" style="color:var(--app-color);"> </i> -->
            <img src="@/assets/oasis_logo_small.png">
            
        </div>
        <div v-else class="welcome-message">
            <!-- <i class="fad fa-hand-peace" style="color:var(--app-color);"> </i> -->
            <img src="@/assets/hand-peace.png">
            Aloha
        </div>
        
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default{
    name:'splash',
    data(){
        return {
            isLoading:false
        }
    },
    computed:{
        ...mapGetters({
            facility:'FACILITY'
        }),
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        
    }
}
</script>
<style lang="scss">
@import "@/assets/scss.scss";
.welcome-message{
    font-size:15pt;
    letter-spacing: 0px;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: lighter;
    flex-direction: column;
    color:#91a1a4;
    i{
        font-size: 25pt;
        margin-bottom:15px;
    }
    @include media-query($small){
        font-size:15pt;
        img{
            width:100%;
        }
    }
}
</style>