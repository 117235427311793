import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Input from './inputs/text-input';
import Button from './inputs/button';
import Dialog from './inputs/dialog';
import Card from './inputs/card';
import RadioGroup from './inputs/radio-group';
import Radio from './inputs/radio';
import Select from './inputs/select';
import TextArea from './inputs/textarea';
import  Vuetify from './plugins/vuetify';
import { ClientTable} from 'vue-tables-2';
import { store } from "./store/store.js";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMq from "vue-mq";

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAy3Rt7-IMFBi39YXHjjKhI0icjWDBM8DM',
    libraries: 'places',
  }
});
Vue.use(VueMq, {
  breakpoints: {
    sm: 728,
    md: 1200,
    lg: Infinity
  }
});

Vue.component('d-text-field', Input)
Vue.component('d-btn', Button)
Vue.component('d-dialog', Dialog)
Vue.component('d-card', Card)
Vue.component('d-radio-group', RadioGroup)
Vue.component('d-radio', Radio)
Vue.component('d-select', Select)
Vue.component('d-textarea', TextArea)

require('./assets/css.css')
require('./assets/css/all.css')
require('./assets/css/animate.css')
require('./assets/css/main.css')
require('./assets/css/style.css')
require('./assets/bootstrap.min.css')
Vue.config.productionTip = false
Vue.use(ClientTable, {options : {}, useVuex : false, theme: 'bootstrap3', template : 'default'});
new Vue({
  vuetify: Vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
