<template>
    <div class="dash-container" id="dash">
        <div class="show-archived" @click="showArchived = !showArchived"><i class="fal fa-angle-left"></i></div>
        <v-dialog v-model="leadOpen" width="1200px">
            <EntryPopup @close="closeLead" :entry="activeLead" />
        </v-dialog>
        <div class="d-inner">

            <div class="c-card" style="grid-column: span 1;">
                <div class="c-title">
                    <div style="display:flex; align-items:center; gap:10px;">Leads<v-btn
                            @click="$router.push('/lead/new')" style="margin-left:10px;" depressed icon><i
                                style="margin:0" class="fa fa-plus"></i></v-btn></div>
                    <!-- <div class="search-for-leads">
                    <div class="search-for-leads-input">
                        <i class="fal fa-search"></i>
                        <input type="text" v-model="leadTerm">
                    </div>
                    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
                    <div class="search-for-leads-menu animted-fast" v-if="leadTerm != ''">
                        <div v-if="!isLoaded" class="search-for-leads-loading">
                            <v-progress-circular indeterminate size="20" width="2" color="#000000"></v-progress-circular>
                        </div>
                        <div v-show="isLoaded" class="search-for-leads-menu-item" v-for="(lead, i) in addLeads" :key="i">
                            {{ lead.name }}
                        </div>
                    </div>

                    </transition>
                    
                </div>
                <v-combobox
                v-if="false"
                    :loading="!isLoaded"
                    value=""
                    :search-input.sync="leadTerm"
                    :items.sync="addLeads"
                    menu-props="auto"
                    outlined
                    dense
                    hide-details
                    chips
                    label="Search"
                    
                >
                <template v-slot:item="{ item }">
                
                <v-chip
                @click.prevent="chooseLead(item)"
                :color="`#ff9900`"
                dark
                label
                small
                >
                {{ item }}
                </v-chip>
                
                
            </template>
</v-combobox> -->
                </div>

                <div class="c-card-inner">

                    <div v-if="true" ref="recentContainer" class="leads animated">
                        <div class="lead-item" @click="activateLead(item, 'leads')"
                            @mousedown="startDrag($event, 'leads', item)" @mouseup="endDrag" v-for="(item, i) in leads"
                            :id="`leads-${i}`" :key="i">

                            <div :class="{ 'g-ads': item.source && item.source.indexOf('Goog') > -1, 'lux-rehab': item.source && item.source.indexOf('rehabpath') > -1 }"
                                class="lead-item-inner">
                                <span style="white-space:nowrap">{{ item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
            item.insurance_company !=
                null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>
                        <div class="none" v-if="leads.length == 0 && !isDragging"> <span
                                style="background:#eaeaea; padding:7px 15px; border-radius:100px; ">No Results</span>
                        </div>
                    </div>


                </div>
                <div v-if="isDragging" class="drag-container animated" :class="{ active: hoverBucket == 'leads' }"
                    id="d-leads">
                    <i class="fal fa-plus"></i> Leads
                </div>
            </div>

            <div class="c-card">
                <div class="c-title">VOB<br><span style="font-size:.7em; color:#808080"></span></div>
                <div class="c-card-inner">

                    <div v-if="true" class="leads">
                        <div class="lead-item" @click="activateLead(item, 'vob')"
                            @mousedown="startDrag($event, 'vob', item)" @mouseup="endDrag" v-for="(item, i) in vob"
                            :id="`vob-${i}`" :key="i">
                            <div :class="{ 'g-ads': item.source && item.source.indexOf('Goog') > -1, 'lux-rehab': item.source && item.source.indexOf('rehabpath') > -1 }"
                                class="lead-item-inner">
                                <span style="white-space:nowrap">{{ item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
            item.insurance_company != null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>
                        <div class="none" v-if="vob.length == 0 && !isDragging"> <span
                                style="background:#eaeaea; padding:7px 15px; border-radius:100px; ">No Results</span>
                        </div>
                    </div>


                </div>
                <div v-if="isDragging" class="drag-container animated" :class="{ active: hoverBucket == 'vob' }"
                    id="d-vob">
                    <i class="fal fa-plus"></i> VOB
                </div>
            </div>

            <div class="c-card">
                <div class="c-title">Qualified<br><span style="font-size:.7em; color:#808080"></span></div>
                <div class="c-card-inner">

                    <div v-if="true" class="leads">
                        <div class="lead-item" @click="activateLead(item, 'qualified')"
                            @mousedown="startDrag($event, 'qualified', item)" @mouseup="endDrag"
                            v-for="(item, i) in qualified" :id="`qualified-${i}`" :key="i">
                            <div :class="{ 'g-ads': item.source && item.source.indexOf('Goog') > -1, 'lux-rehab': item.source && item.source.indexOf('rehabpath') > -1 }"
                                class="lead-item-inner">
                                <span style="white-space:nowrap">{{ item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
            item.insurance_company != null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>
                        <div class="none" v-if="qualified.length == 0 && !isDragging"> <span
                                style="background:#eaeaea; padding:7px 15px; border-radius:100px; ">No Results</span>
                        </div>
                    </div>


                </div>
                <div v-if="isDragging" class="drag-container animated" :class="{ active: hoverBucket == 'qualified' }"
                    id="d-qualified">
                    <i class="fal fa-plus"></i> Qualified
                </div>
            </div>

            <div class="c-card ">
                <div class="c-title">Closed </div>
                <div v-if="true" class="c-card-inner">

                    <div v-if="true" class="leads animated">
                        <div class="lead-item" @click="activateLead(item, 'closed')"
                            @mousedown="startDrag($event, 'closed', item)" @mouseup="endDrag" v-for="(item, i) in closed"
                            :id="`closed-${i}`" :key="i">
                            <div class="lead-item-inner">
                                <span style="white-space:nowrap">{{ item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
            item.insurance_company != null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>
                        <div class="none" v-if="closed.length == 0 && !isDragging"> <span
                                style="background:#eaeaea; padding:7px 15px; border-radius:100px; ">No Results</span>
                        </div>
                    </div>


                </div>
                <div v-show="isDragging" class="drag-container animated" :class="{ active: hoverBucket == 'closed' }"
                    id="d-closed">

                </div>
            </div>

            <div class="c-card">
                <div class="c-title">Admitted </div>
                <div class="c-card-inner">

                    <div v-if="true" class="leads animated">
                        <div class="lead-item" @click="activateLead(item, 'admitted')"
                            @mousedown="startDrag($event, 'admitted', item)" @mouseup="endDrag"
                            v-for="(item, i) in admitted" :id="`admitted-${i}`" :key="i">
                            <div class="lead-item-inner">
                                <span style="white-space:nowrap">{{ item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
            item.insurance_company != null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>
                        <div class="none" v-if="admitted.length == 0 && !isDragging"> <span
                                style="background:#eaeaea; padding:7px 15px; border-radius:100px; ">No Results</span>
                        </div>
                    </div>


                </div>
                <div v-show="isDragging" class="drag-container animated" :class="{ active: hoverBucket == 'admitted' }"
                    id="d-admitted">

                </div>
            </div>

            <div class="c-card ">
                <div class="c-title">Waitlist </div>
                <div v-if="true" class="c-card-inner">

                    <div v-if="true" class="leads animated">
                        <div class="lead-item" @click="activateLead(item, 'waitlist')"
                            @mousedown="startDrag($event, 'waitlist', item)" @mouseup="endDrag"
                            v-for="(item, i) in waitlist" :id="`waitlist-${i}`" :key="i">
                            <div class="lead-item-inner">
                                <span style="white-space:nowrap">{{ item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
            item.insurance_company != null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>
                        <div class="none" v-if="waitlist.length == 0 && !isDragging"> <span
                                style="background:#eaeaea; padding:7px 15px; border-radius:100px; ">No Results</span>
                        </div>
                    </div>


                </div>
                <div v-show="isDragging" class="drag-container animated" :class="{ active: hoverBucket == 'waitlist' }"
                    id="d-waitlist">

                </div>
            </div>

        </div>
        <div class="d-inner-bottom">
            <div class="c-card" style="grid-column: span 1;">
                <div class="c-title">
                    <div style="display:flex; align-items:center; gap:10px;">Lost</div>


                </div>

                <div class="c-card-inner">

                    <div v-if="true" ref="recentContainer" class="leads animated">
                        <div class="lead-item" @click="activateLead(item, 'lost')"
                            @mousedown="startDrag($event, 'lost', item)" @mouseup="endDrag" v-for="(item, i) in lost"
                            :id="`lost-${i}`" :key="i">

                            <div :class="{ 'g-ads': item.source && item.source.indexOf('Goog') > -1, 'lux-rehab': item.source && item.source.indexOf('rehabpath') > -1 }"
                                class="lead-item-inner">
                                <span style="white-space:nowrap">{{ item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
            item.insurance_company != null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>

                    </div>


                </div>
                <div v-if="isDragging" class="drag-container animated" :class="{ active: hoverBucket == 'lost' }"
                    id="d-lost">
                    <i class="fal fa-plus"></i> Lost
                </div>
            </div>
            <div class="c-card" style="grid-column: span 1;">
                <div class="c-title">
                    <div style="display:flex; align-items:center; gap:10px;">Unqualified</div>


                </div>

                <div class="c-card-inner">

                    <div v-if="true" ref="recentContainer" class="leads animated">
                        <div class="lead-item" @click="activateLead(item, 'unqualified')"
                            @mousedown="startDrag($event, 'unqualified', item)" @mouseup="endDrag"
                            v-for="(item, i) in unqualified" :id="`unqualified-${i}`" :key="i">

                            <div :class="{ 'g-ads': item.source && item.source.indexOf('Goog') > -1, 'lux-rehab': item.source && item.source.indexOf('rehabpath') > -1 }"
                                class="lead-item-inner">
                                <span style="white-space:nowrap">{{ item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
            item.insurance_company != null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>

                    </div>


                </div>
                <div v-if="isDragging" class="drag-container animated" :class="{ active: hoverBucket == 'unqualified' }"
                    id="d-unqualified">
                    <i class="fal fa-plus"></i> Unqualified
                </div>
            </div>
            <div class="c-card" style="grid-column: span 1;">
                <div class="c-title">
                    <div style="display:flex; align-items:center; gap:10px;">Referred</div>


                </div>

                <div class="c-card-inner">

                    <div v-if="true" ref="recentContainer" class="leads animated">
                        <div class="lead-item" @click="activateLead(item, 'referred')"
                            @mousedown="startDrag($event, 'referred', item)" @mouseup="endDrag"
                            v-for="(item, i) in referred" :id="`referred-${i}`" :key="i">

                            <div :class="{ 'g-ads': item.source && item.source.indexOf('Goog') > -1, 'lux-rehab': item.source && item.source.indexOf('rehabpath') > -1 }"
                                class="lead-item-inner">
                                <span style="white-space:nowrap">{{ item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
            item.insurance_company != null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>

                    </div>


                </div>
                <div v-if="isDragging" class="drag-container animated" :class="{active:hoverBucket == 'referred'}"
                    id="d-referred">
                    <i class="fal fa-plus"></i> Referred
                </div>
            </div>

            <div></div>

            <div class="c-card" style="grid-column: span 1;">
                <div class="c-title">
                    <div style="display:flex; align-items:center; gap:10px;">Discharged</div>


                </div>

                <div class="c-card-inner">

                    <div v-if="true" ref="recentContainer" class="leads animated">
                        <div class="lead-item" @click="activateLead(item,'discharged')"
                            @mousedown="startDrag($event, 'discharged', item)" @mouseup="endDrag"
                            v-for="(item,i) in discharged" :id="`discharged-${i}`" :key="i">

                            <div :class="{'g-ads':item.source && item.source.indexOf('Goog') > -1, 'lux-rehab':item.source && item.source.indexOf('rehabpath') > -1}"
                                class="lead-item-inner">
                                <span style="white-space:nowrap">{{item.name == '' || item.name == null ? 'No
                                    Name':item.name}}</span>
                                <span class="pat-insurance" style="margin-left:auto;"
                                    :class="item.insurance_company && item.insurance_company.toLowerCase()">{{
                                    item.insurance_company != null ? item.insurance_company : 'None' }}</span>
                            </div>
                        </div>

                    </div>


                </div>
                <div v-if="isDragging" class="drag-container animated" :class="{active:hoverBucket == 'discharged'}"
                    id="d-discharged">
                    <i class="fal fa-plus"></i> Discharged
                </div>
            </div>
        </div>
        <div v-if="isDragging" style="width:100%;position:absolute; top:0;left:0;">
            <div class="drag-container animated"
                style="height:75px; display:flex; align-items: center; justify-content: center;  z-index: 1000;"
                id="d-archive" :class="{active:hoverBucket == 'archive'}">
                <i class="fal fa-trash"></i>
            </div>
        </div>
    </div>



    <!-- <div class="c-card">
            <div class="c-title">Incoming Patients</div>
            <div style="display:flex;">
                <div class="leads">
                   <div class="lead-item" v-for="(item,i) in incoming" :key="i">
                    <div class="n-tag">IN</div> {{item.name}} <span class="timeago">In {{moment().diff(moment(item.treatment_start_date), 'days')*-1 + ' days'}}</span>
                </div> 
                <div class="none" v-if="incoming.length == 0"> <span style="background:#eaeaea; padding:7px 15px; border-radius:100px; ">No Results</span></div>
                </div>
                
            </div>
        </div>
        <div class="c-card">
            <div class="c-title">Outgoing Patients</div>
            <div style="display:flex;">
                <div class="leads">
                   <div class="lead-item" v-for="(item,i) in outgoing" :key="i">
                    <div class="n-tag">OUT</div> {{item.name}} <span class="timeago">In {{moment().diff(moment(item.treatment_end_date), 'days')*-1 + ' days'}}</span>
                </div> 
                <div class="none" v-if="outgoing.length == 0"> <span style="background:#eaeaea; padding:7px 15px; border-radius:100px; ">No Results</span></div>
                </div>
                
            </div>
        </div> -->





</template>
<script>
import axios from 'axios';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import EntryPopup from '../components/entryPopup.vue';

const CancelToken = axios.CancelToken;
export default {
    name: 'Dashboard',
    components: {
        EntryPopup
    },
    data() {
        return {
            isLoaded: true,
            leadTerm: '',
            addLeads: [{ name: 'No Names' }],
            showArchived: false,
            notesLoading: false,
            newNoteMessage: '',
            showAddNote: false,
            leadNotes: [],
            calVal: moment().format('YYYY-MM-DD'),
            moment,
            recent_leads: [],
            rehabPathSource: require('@/assets/rehabpath.svg'),
            psychtodaySource: require('@/assets/psychtoday.png'),
            googleAdsSource: require('@/assets/googleAds.png'),
            gmbSource: require('@/assets/gmb.png'),
            websiteSource: require('@/assets/website.png'),
            events: [
                // {
                //     name:'James Morales In',
                //     start: new Date(),
                //     end: new Date(),
                //     color: '#44aa66',
                //     timed: false,
                // },
                // {
                //     name:'James Morales Out',
                //     start:moment('2022-05-22').toDate(),
                //     end: moment('2022-05-22').toDate(),
                //     color: '#aa0000',
                //     timed: false,
                // },
                // {
                //     name:'Wendy Byrd In',
                //     start: moment('2022-05-18').toDate(),
                //     end: moment('2022-05-18').toDate(),
                //     color: '#44aa66',
                //     timed: false,
                // },
                // {
                //     name:'Wendy Byrd Out',
                //     start: moment('2022-05-30').toDate(),
                //     end: moment('2022-05-30').toDate(),
                //     color: '#aa0000',
                //     timed: false,
                // }
            ],
            cancel: null,
            savingTag: false,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            incoming: [],
            outgoing: [],
            leadOpen: false,
            activeLead: {},
            isDragging: false,
            timeout: null,
            clone: null,
            dw: null,
            dh: null,
            hoverBucket: null,
            hoverIdx: 0,
            startIdx: 0,
            startBucket: null,
            recent: [],
            refer: [],
            leads: [],
            admitted: [],
            vob: [],
            qualified: [],
            closed: [],
            discharged: [],
            lost: [],
            referred: [],
            unqualified: [],
            ready: [],
            archive: [],
            waitlist: [],
            savingUser: false,
            savingStatus: false,
            savingInsurance: false,
            activateNextPage: false,
            activeLeadType: ''
        }
    },
    watch: {
        leadTerm(v) {
            if (this.cancel != null) {
                this.cancel();
            }

            if (v != '') {
                this.goSearch();
            } else {
                this.addLeads = [];
                this.isLoaded = true;
            }
        }
    },
    methods: {
        async updateCallRail(type, lead){
            
            try{
                let amount = 0;
                let interactionId = '';
            if(lead.reqBody == null){
                return;
            }else if(lead.activity == null && lead.reqBody == null){
                return;
            }else{
                // $100 call, qualified $1200, Admit $15000, Insurance Verification Form $250
                
                if(lead.reqBody != null){
                    let rbody = JSON.parse(lead.reqBody);
                    if(rbody.resource_id != null && rbody.resource_id != '' && (rbody.resource_id.indexOf('CAL') > -1  || rbody.resource_id.indexOf('FRM') > -1)){
                        interactionId = rbody.resource_id
                    }else if(rbody.id != null && (rbody.id.indexOf('CAL') > -1  || rbody.id.indexOf('FRM') > -1) ){
                        interactionId = rbody.id
                    }
                }else{
                    if(lead.activity.length){
                        if(lead.activity[0].resource_id != null && lead.activity[0].resource_id != '' && (lead.activity[0].resource_id.indexOf('CAL') > -1  || lead.activity[0].resource_id.indexOf('FRM') > -1)){
                            interactionId = lead.activity[0].resource_id
                        }else if(lead.activity[0].id != null && (lead.activity[0].id.indexOf('CAL') > -1  || lead.activity[0].id.indexOf('FRM') > -1) ){
                            interactionId = lead.activity[0].id
                        }
                        
                    }
                }
                
            }
                if(type == 'vob'){
                    amount =  250
                }
                if(type == 'qualified'){
                    amount = 1200
                }
                if(type == 'admitted'){
                    amount = 15000
                }
                if(amount > 0 && interactionId.indexOf('CAL') > -1 || interactionId.indexOf('FRM') > -1){
                let post = {
                    amount,
                    id:interactionId,
                    type:interactionId.indexOf('CAL') > -1 ? 'call' : 'form'
                }
                console.log(post);
                await axios.post('/api/callrail/update_entry', post)
                }else{
                    window.alert('NO ID FOUND TO UPDATE')
                }
            }catch(e){
                console.log(e);
                console.log('ERROR');
            }
            
            // console.log('TODO');
           
        },
        goSearch() {
            this.isLoaded = false;

            this.$nextTick(() => {

                let post = {
                    field: 'name',
                    term: this.leadTerm,
                    page: 1,
                    sortName: 'name',
                    sort: 'dec',
                    filters: [
                        {
                            "name": "status",
                            "type": "string",
                            "value": [
                                "recent"
                            ],
                            "operator": "array"
                        }
                    ],
                    type: "master",
                };
                axios.post(`/api/leads/search?facility=${this.facility}`, post, {
                    cancelToken: new CancelToken((c) => {
                        this.cancel = c;
                    }),
                }).then(async (res) => {
                    let clients = JSON.parse(JSON.stringify(res.data.items));
                    for (let i in clients) {
                        if (clients[i].status == null) {
                            clients[i].status = 'none'
                        }
                        if (clients[i].tag == null) {
                            clients[i].tag = ''
                        }
                    }
                    if (this.filterSort == 'dec') {

                    } else {
                        clients = clients.reverse();
                    }
                    this.$nextTick(() => {
                        const leads = clients.map((item) => {
                            return {

                                "name": item.name || 'No Name'

                            }
                        })
                        this.addLeads = leads.sort((a, b) => {
                            const keyword = this.leadTerm;
                            if (a.name.toLowerCase().indexOf(keyword.toLowerCase()) > b.name.toLowerCase().indexOf(keyword.toLowerCase())) {
                                return 1;
                            } else if (a.name.toLowerCase().indexOf(keyword.toLowerCase()) < b.name.toLowerCase().indexOf(keyword.toLowerCase())) {
                                return -1;
                            } else {
                                if (a.name > b.name)
                                    return 1;
                                else
                                    return -1;
                            }
                        });
                        // console.log(leads);
                        // // this.addLeads = JSON.parse(JSON.stringify(leads))
                        // if(leads.length > 0){
                        //     this.$set(this, 'addLeads', leads)
                        // }   

                        // console.log(this.addLeads);
                    })



                    this.isLoaded = true;

                    // this.total = res.data.total;
                });
            })
        },
        getAssignedRep(id) {
            let u = this.users.filter((item) => item._id == id);
            if (u.length > 0) {
                return u[0].first_name + ' ' + u[0].last_name
            } else {
                return ''
            }
        },
        async changeAssignedUser(user) {

            this.savingUser = true;
            let post = {
                assignedUser: user
            }
            try {
                let { data } = await axios.put('/api/leads/edit/' + this.activeLead._id + `?userId=${this.user.id}`, post);
                this.savingUser = false;
            } catch (e) {
                this.savingUser = false;
            }

        },

        async addNote() {
            this.notesLoading = true;
            let note = {
                lead_id: this.activeLead._id,
                date: new Date(),
                message: this.newNoteMessage,
                user_name: this.user.first_name + ' ' + this.user.last_name,
                user_id: this.user.id
            }
            await axios.post(`/api/leads/add_note/${this.activeLead._id}`, note);
            this.showAddNote = false;
            this.newNoteMessage = '';
            this.getNotes(this.activeLead._id);
        },

        async getNotes(id) {
            this.notesLoading = true;
            let { data } = await axios.get('/api/leads/get_notes/' + id);
            this.leadNotes = data.notes.reverse();
            this.notesLoading = false;
        },
        handleMouseMove(event) {
            var eventDoc, doc, body;

            event = event || window.event; // IE-ism

            // If pageX/Y aren't available and clientX/Y are,
            // calculate pageX/Y - logic taken from jQuery.
            // (This is to support old IE)
            if (event.pageX == null && event.clientX != null) {
                eventDoc = (event.target && event.target.ownerDocument) || document;
                doc = eventDoc.documentElement;
                body = eventDoc.body;

                event.pageX = event.clientX +
                    (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
                    (doc && doc.clientLeft || body && body.clientLeft || 0);
                event.pageY = event.clientY +
                    (doc && doc.scrollTop || body && body.scrollTop || 0) -
                    (doc && doc.clientTop || body && body.clientTop || 0);
            }
            this.clone.style.top = event.pageY - (this.dh * .5) + 'px';
            this.clone.style.left = event.pageX - 15 + 'px';
            let els = document.elementsFromPoint(event.pageX, event.pageY);
            for (let i in els) {
                if (els[i].className == 'lead-item') {
                    this.hoverIdx = els[i].id.split('-')[1];
                    this.hoverBucket = els[i].id.split('-')[0];
                    if (this.startBucket != this.hoverBucket) {
                        this[this.startBucket].splice(this[this.startBucket].findIndex((item) => item._id == this.activeLead._id), 1);
                        this.startBucket = this.hoverBucket;
                    }
                    this[this.hoverBucket].splice(this[this.hoverBucket].findIndex((item) => item._id == this.activeLead._id), 1);
                    this[this.hoverBucket].splice(this.hoverIdx, 0, this.activeLead);


                    //console.log(this[this.startBucket].findIndex((item)=>item._id == this.activeLead._id));
                }
                if (els[i].className == 'drag-container animated') {



                    this.hoverBucket = els[i].id.split('d-')[1]
                    if (this[this.hoverBucket] == null) return;
                    this[this.hoverBucket].splice(this.hoverIdx, 0, this.activeLead);
                    if (this.startBucket != this.hoverBucket) {
                        this[this.startBucket].splice(this[this.startBucket].findIndex((item) => item._id == this.activeLead._id), 1);
                        this.startBucket = this.hoverBucket;
                    }


                }

            }
            return { x: event.pageX, y: event.pageY }
        },
        drag(e) {

        },
        startDrag(e, start, lead) {
            this.activeLead = lead;
            e.preventDefault();
            this.startBucket = start;
            //this.startIdx = e.target.id

            this.timeout = setTimeout(() => {
                let el = e.target;
                function getParent(node) {
                    node = node.parentNode;
                    if (node.className != 'lead-item') {
                        node = getParent(node);
                    }
                    return node;
                }
                if (el.className == 'lead-item') {

                } else {
                    el = getParent(el);
                }

                let bb = el.getBoundingClientRect();
                this.dw = bb.width;
                this.dh = bb.height;
                this.clone = el.cloneNode(true);
                this.clone.style.width = this.dw + 'px';
                let body = window.document.getElementById('dash');
                body.appendChild(this.clone);
                this.clone.classList.add('dragging');
                this.isDragging = true;
                this.handleMouseMove(e);
                window.addEventListener('mousemove', this.handleMouseMove);
                window.addEventListener('mouseup', this.endDrag)
            }, 500)
        },
        async endDrag() {
            if (this.isDragging) {
                this.isDragging = false;
                window.removeEventListener('mouseup', this.endDrag)
                window.removeEventListener('mousemove', this.handleMouseMove);
                let body = window.document.getElementById('dash');
                let lead = JSON.parse(JSON.stringify(this.activeLead));
                delete lead._id;
                lead.status = this.hoverBucket;
                if (this.hoverBucket == 'leads') {
                    lead.status = 'recent';
                }
                if (this.hoverBucket == 'vob') {
                    this.updateCallRail('vob', lead);
                }
                if (this.hoverBucket == 'qualified') {
                    this.updateCallRail('qualified', lead);
                }
                
                
                //  this[this.startBucket].splice(this[this.startBucket].findIndex((item)=>item._id == this.activeLead._id), 1);
                if (this.hoverBucket != 'archive') {
                    //   this[this.hoverBucket].splice(this.hoverIdx, 0, this.activeLead);
                }

                body.removeChild(this.clone);
                await axios.put('/api/leads/edit/' + this.activeLead._id + `?userId=${this.user.id}`, lead)


            } else {
                clearTimeout(this.timeout);
                this.isDragging = false;
            }
        },
        closeLead() {
            this.activeLead = {};
            this.leadNotes = [];
            this.leadOpen = false;
        },
        async activateLead(lead, type) {
            this.activeLeadType = type;
            this.$nextTick(() => {
                this.getNotes(lead._id);
                this.activeLead = lead;
                this.leadOpen = true;
            })
        },
        async getPatients() {
            let incoming = await axios.get(`/api/leads/incoming?facility=${this.facility}`);
            incoming = incoming.data.items;
            let outgoing = await axios.get(`/api/leads/outgoing?facility=${this.facility}`);
            outgoing = outgoing.data.items;
            this.outgoing = outgoing;
            this.incoming = incoming;
            for (let i in incoming) {
                this.events.push({
                    name: incoming[i].name + ' IN',
                    start: new Date(incoming[i].treatment_start_date),
                    end: new Date(incoming[i].treatment_start_date),
                    color: '#44aa66',
                    timed: false
                })
            }
            for (let i in outgoing) {
                this.events.push({
                    name: incoming[i].name + ' OUT',
                    start: new Date(incoming[i].treatment_end_date),
                    end: new Date(incoming[i].treatment_end_date),
                    color: '#aa0000',
                    timed: false
                })
            }

        },
        async getLeads(type) {
            let { data } = await axios.post(`/api/leads/get_leads?facility=${this.facility}`, { type });
            let it = data.items.sort((a, b) => {
                if (new Date(a.time) < new Date(b.time)) {
                    return 1
                } else {
                    return -1
                }
            })
            this[type] = it;
        },
        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
    },
    mounted() {
        this.getLeads('leads');
        this.getLeads('vob');
        this.getLeads('qualified');
        this.getLeads('closed');
        this.getLeads('admitted');
        this.getLeads('waitlist');
        // this.getLeads('qualified');
        // this.getLeads('archive');
        // this.getLeads('refer');
        // this.getLeads('admitted');


        // this.getPatients();
        if (this.user.role != 'admin' &&
            this.user.role == 'forms'
        ) {
            this.$router.push('/forms');
        }
        window.addEventListener('keydown', (e) => {

            switch (e.keyCode) {
                case 37:
                    //alert('left');
                    if (this.activateNextPage) return;
                    if (this.activeLead != null) {
                        let index = this[this.activeLeadType].findIndex((item) => item._id == this.activeLead._id);
                        if (index <= this[this.activeLeadType].length && index > 0) {
                            this.activeLead = this[this.activeLeadType][index - 1]
                        }
                        // if(index == 0){
                        //   this.activateNextPage = true;
                        //   this.$router.push({query: {...this.$route.query, page: Number(this.$route.query.page) + 1}})
                        //   window.location.reload();
                        // }
                    }
                    break;
                case 39:
                    //alert('right');
                    if (this.activateNextPage) return;
                    if (this.activeLead != null) {
                        let index = this[this.activeLeadType].findIndex((item) => item._id == this.activeLead._id);

                        if (index < this[this.activeLeadType].length - 1 && index >= 0) {
                            this.activeLead = this[this.activeLeadType][index + 1]
                        }
                        // if(index == this[this.activeLeadType].length - 1){
                        //   this.activateNextPage = true;
                        //   this.$router.push({query: {...this.$route.query, page: Number(this.$route.query.page) + 1}})
                        //   window.location.reload();
                        // }
                    }
                    break;
            }
        })
    },
    computed: {
        ...mapGetters({
            user: 'AUTH_USER',
            users: 'AUTH_USERS',
            facility: 'FACILITY'
        }),
        availableStatuses() {
            return [
                { text: 'Recent', value: 'recent' },
                { text: 'VOB', value: 'vob' },
                { text: 'Qualified', value: 'qualified' },
                { text: 'Unqualified', value: 'unqualified' },
                { text: 'Lost', value: 'lost' },
                { text: 'Closed', value: 'closed' },
                // {text:'Referred', value:'referred'},
                { text: 'Admitted', value: 'admitted' },
                { text: 'Archived', value: 'archive' },
                { text: 'Waitlist', value: 'waitlist' },
                { text: 'Discharged', value: 'discharged' }
            ]
        },
        availableInsurances() {
            return [
                { text: 'None', value: '' },
                { text: 'Aetna', value: 'aetna' },
                { text: 'Cigna', value: 'cigna' },
                { text: 'Medicaid', value: 'medicaid' },
                { text: 'UMR', value: 'umr' },
                { text: 'Humana', value: 'humana' },
                { text: 'Optum', value: 'optum' },
                { text: 'Beacon', value: 'beacon' },
                { text: 'United Health', value: 'united' },
                { text: 'BCBS', value: 'bcbs' },
                { text: 'HMSA', value: 'hmsa' },
                { text: 'HMAA', value: 'hmaa' },
                { text: 'UHA', value: 'uha' },
                { text: 'Private Pay', value: 'private_pay' },

            ]
        },
        availableUsers() {

            return this.users.map((item) => {

                return { text: `${item.first_name} ${item.last_name}`, value: item._id };
            })
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss.scss";

.add-note-icon {
    padding: 10px;
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.1, 1.1);
    }
}

.note-item {
    padding: 15px 8px;
    width: 100%;
    border: solid 1px #eaeaea;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: left;

    .note-title {
        display: flex;
        margin-bottom: 10px;
        font-size: 10pt;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #808080;
        display: flex;
        align-items: center;

        .fa-trash {
            margin-left: 10px;
            transition: all .3s ease;
            cursor: pointer;

            &:hover {
                color: #ff0000;
            }
        }
    }

    .note-body {
        font-size: 12pt;
        white-space: pre-line;

    }
}

.drag-handle-container {
    display: none;
    height: 35px;
    width: 35px;
    color: #aeaeae;
    transform: translateX(-10px);

    align-items: center;
    justify-content: center;

    @include media-query($medium-down) {
        display: none;
    }
}

.active-lead-notes {
    .active-lead-notes-section {
        margin-top: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;

        max-height: 250px;

        overflow: auto;

        .no-notes {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f8fafb;
            border: solid 1px #eaeaea;
            color: #808080;
            font-size: 12pt;
            text-transform: uppercase;
            letter-spacing: 2px;
            height: 100px;
            width: 100%;

        }
    }

    padding:15px;
}

.chip {
    font-size: 9pt;
    font-weight: lighter;
    color: #808080;
    display: inline-block;
    background: #ffffff;
    margin-top: 5px;
    border: solid 1px #eaeaea;
    border-radius: 100px;
    padding: 2px 10px;
    display: none;

    i {
        margin-right: 5px;

    }

}

.c-card {
    position: relative;
    overflow: hidden;
    box-shadow: $box-shadow;
    border-radius: 5px;
    margin-bottom: 15px;
    background: #fff;

    &.small {
        max-height: 100px;
    }

    @include media-query($small) {
        padding: 0 !important;
        box-shadow: none !important;
        min-height: 500px;
    }

    .c-title {
        cursor: pointer;
        width: 100%;

        i {
            margin-right: 10px;
            transform-origin: center center;

            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all .3s ease;
            margin-left: auto;
        }

        padding:25px;
        font-size:15pt;
        text-transform: uppercase;
        color:var(--app-color);
        letter-spacing: 2px;
        font-weight: bold;
        text-align: left;

    }

    .c-card-inner {
        display: flex;
        padding: 15px;
        max-height: calc(100% - 130px);
        overflow: auto;
        overflow-x: hidden;
        margin-top: 15px;
        box-sizing: border-box;

        @include media-query($small) {
            padding: 10px;
            background: #f8fafb;
            border: solid 1px #eaeaea;
        }

    }
}

.active-lead {

    .active-lead-title {
        font-size: 15pt;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #808080;

        @include media-query($medium-down) {
            font-size: 10pt;
        }
    }

    .active-lead-text {

        .flex {
            flex-wrap: wrap !important;
        }

        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:15px;
        padding:15px;
        text-align:left;

        @include media-query($medium-down) {
            grid-template-columns: 1fr;
            display: flex;
        }

        .active-lead-item {
            width: 100%;


            .active-lead-item-title {
                font-size: 9pt;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;

            }

            .active-lead-item-value {
                margin-left: auto;
            }
        }
    }
}

.d-inner {
    display: grid;
    width: 100%;
    padding: 25px;
    grid-gap: 25px;
    flex: 2;
    height: 80%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    @include media-query($medium-down) {
        display: unset;
        padding: 0px;
    }

}

.d-inner-bottom {
    display: grid;
    width: 100%;
    padding: 25px;
    grid-gap: 25px;
    flex: 1;
    height: 20%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    @include media-query($medium-down) {
        display: unset;
        padding: 0px;
    }

}

.none {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10pt;
    color: #808080;
    height: 50px;
    width: 100%;

    text-transform: uppercase;
    letter-spacing: 2px;
}

.drag-container {
    width: 100%;
    display: none;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #eaeaea;
    color: #808080;
    font-size: 15pt;
    text-transform: uppercase;
    opacity: .3;
    bottom: 0;

    &.active {
        background: #e0e0e0;
        opacity: .6;
    }

    i {
        margin-right: 10px;
    }

    transition:all .3s ease;
    letter-spacing: 2px;
    border:dashed 2px #aeaeae;
    display:flex;
    align-items: center;
    justify-content: center;

}

.dash-container {

    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-left: 265px;
    height: calc(100vh - 75px);
    position: relative;
    max-height: calc(100vh - 75px);

    @include media-query($medium-down) {
        padding: 15px;
        padding-top: 85px
    }

    h1 {
        color:var(--app-color);
        text-transform: uppercase;
        font-size: 12pt;
        letter-spacing: 7px;
    }
}

.n-tag {
    color: #fff;
    background:var(--app-color);
    border-radius: 100px;
    padding: 2px 7px;
    margin-right: 10px;
    display: flex;

    font-size: 7pt;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    display: none;

}

.timeago {
    color: #808080;
    font-size: .8em;
    margin-left: 10px;
    white-space: nowrap;
    display: none;
}

.leads {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media-query($medium-down) {
        padding-right: 10px;
        max-height: 300px;
    }

}

.lead-item {

    &.dragging {
        position: fixed;
        z-index: 1000;
        cursor: grabbing !important;

        i {
            cursor: grabbing;
        }

    }

    &:hover {
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
        transform: scale(1.00, 1.00);
    }

    .lead-item-inner {
        flex: 1;
        display: flex;
        align-items: center;
    }

    cursor: pointer;
    transition:box-shadow,
    transform .3s ease;
    position:relative;
    display:flex;
    align-items: center;
    background: #fff;
    box-shadow: none;
    padding:5px;
    padding-top:5px;
    margin-bottom:10px;
    width:100%;
    flex-basis: 100%;
    flex:1;
    font-weight: bold;
    color:$blue;
    border:solid 1px #eaeaea;

    @include media-query($medium-down) {
        border: solid 1px #eaeaea;
        box-shadow: none;
    }
}

.side-drawer {
    height: calc(100vh - 120px);
    max-width: 0px;
    transition: all .3s ease;
    overflow: hidden;
    padding-top: 25px;
    height: 100%;
    transform-origin: 0% 0%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 220px);

    &.active {
        max-width: 350px;
    }
}

.archive-panel {



    width: 100%;
    overflow: hidden;

    height: calc(100vh - 220px);
    transition: all .3s ease;
    position: relative;
    // flex:1;

    .c-card {
        flex: 1;
        background: #fff;
        height: 100%;
        min-width: 350px;
    }

}

.show-archived {
    background: #eaeaea;
    color: #808080;
    border-radius: 100px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    position: absolute;
    top: 80px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: all .3s ease;

    &:hover {
        background: #aeaeae;
        color: #000;

    }
}

.type-img {
    position: absolute;
    right: 5px;
    top: 5px;
    max-width: 50px;
    display: none;

    img {
        max-width: 50px;
        width: 100%;
    }
}

.search-for-leads {
    height: 35px;
    width: 100%;
    position: relative;

    .search-for-leads-input {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 35px;
        border: solid 1px #eaeaea;
        border-radius: 3px;
        padding: 4px;
        position: relative;

        i {
            font-size: 10pt;
            color: #000;
        }

        input {
            border: none;
            outline: none;

        }
    }

    .search-for-leads-menu {
        position: absolute;
        z-index: 100;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        background: #fff;
        max-height: 300px;
        width: 100%;
        top: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        overflow: auto;

        .search-for-leads-loading {
            height: 300px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .search-for-leads-menu-item {
            display: flex;
            align-items: center;
            padding: 10px;
            font-size: 12pt;
        }
    }
}
.pat-insurance{
    background:#808080;
}
</style>