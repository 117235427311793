<template>
  <div class="inner-stage" style="text-align:left;">
    <v-dialog width="500" v-model="viewReceiptDialog">
                <v-card>
                    <v-card-title>Receipt</v-card-title>
                    <v-card-text style="text-align:left; display:flex; flex-direction: column; gap:15px;">
                        <img :src="previewReceipt" alt="">
                    </v-card-text>
                    <v-card-actions >
                      <div style="display:flex; width:100%; align-items: center; gap:15px; justify-content: flex-end;">
                        <v-btn @click="viewReceiptDialog = false" depressed text>Close</v-btn>
                      </div>
                    </v-card-actions>
                </v-card>
    </v-dialog>
    <v-dialog width="500" v-model="createExpenseDialog">
                <v-card>
                    <v-card-title>Create Expense</v-card-title>
                    <v-card-text style="text-align:left; display:flex; flex-direction: column; gap:15px;">Add Expense Details<br>
                        <v-text-field hide-details  style="padding-top:0 !important; " :color="appColor" label="Expense Title" outlined small dense ref=""
                            type="text"
                            v-model="expenseObj.name"></v-text-field>
                        <v-text-field hide-details  style="padding-top:0 !important; " :color="appColor" label="Vendor" outlined small dense ref=""
                            type="text"
                            v-model="expenseObj.vendor"></v-text-field>
                        <v-text-field hide-details  style="padding-top:0 !important; " :color="appColor" label="For" outlined small dense ref=""  type="text"
                            v-model="expenseObj.for"></v-text-field>
                        <v-text-field hide-details style="padding-top:0 !important; " :color="appColor" label="Amount" outlined small dense ref="" :value="receiptAmt" @blur="$event => receiptAmt = formatMoney(tempReceiptAmt)" @change="$event => tempReceiptAmt = $event"></v-text-field>
                        <v-select label="Department"
                            v-model="expenseObj.department" :color="appColor"
                            style="padding-top:0px !important; " hide-no-data outlined dense hide-details
                            :items="[{text:'All', value:'all'}, {text:'RCT', value:'rct'},{text:'Food Service', value:'food service'},{text:'Medical', value:'medical'}]">
                        </v-select>
                        <div>
                        <v-menu v-model="expenseDateMenu" :close-on-content-click="false"
                            max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field hide-details v-model="expenseDateFormatted"
                                    label="Date"
                                    style="padding-top:0 !important;" clearable
                                    @blur="expenseObj.date = parseDate(expenseDateFormatted)"
                                    class="fixMe" outlined dense :color="appColor" readonly
                                    v-bind="attrs" v-on="on"
                                    @click:clear="changeExpenseDate({ label: 'date', type: '', value: '' })"></v-text-field>
                            </template>
                            <v-date-picker :color="appColor" v-model="expenseObj.date"
                                @change="$event => { expenseDateMenu = false; changeExpenseDate({ label: 'date', type: '', value: $event }) }"></v-date-picker>
                        </v-menu>
                        </div>
                        
                        <input type="file" @change="uploadReceipt" style="display:none;" ref="fileInput">
                        <div v-if="receiptImg != '' && receiptImg != null" class="receipt-preview">
                          <img ref="receiptPreview" alt="">
                        </div>
                        <v-btn small depressed style="display:inline-block; width:150px;" :color="appColor" dark @click="uploadReceiptClick">Upload Receipt</v-btn>
                    </v-card-text>
                    <div v-if="createExpenseErrorMessage != ''" style="margin:10px; color:red;">{{createExpenseErrorMessage}}</div>
                    <v-card-actions >
                      <div style="display:flex; width:100%; align-items: center; gap:15px; justify-content: flex-end;">
                        <v-btn @click="createExpenseDialog = false" :disabled="createExpenseLoading" depressed text>Cancel</v-btn>
                        <v-btn @click="createExpense" depressed :color="appColor" dark :loading="createExpenseLoading">Create</v-btn>
                      </div>
                    </v-card-actions>
                </v-card>
    </v-dialog>
    <div v-if="isLoaded" class="forms-container">
      <div style="margin-bottom:15px;">
        <div class="f-name"><i class="fad fa-receipt"></i>Expenses List </div><br>
        <v-btn :color="appColor" small dark depressed @click="createExpenseDialog = true">Create New Expense</v-btn>
       
        <!-- <v-select v-model="fac" outlined small compact dense :items="[{text:'Residential', value:'residential'},{text:'TLC', value:'tlc'}]" :color="appColor" style="max-width:250px" label="Facility"></v-select> -->
      </div>
      <div>
        <v-menu v-model="weekOfMenu" :close-on-content-click="false"
            max-width="290">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details v-model="weekOfFormatted"
                    label="Week Of"
                    
                    style="padding-top:0 !important; display:inline-block" clearable
                    @blur="weekOf = parseDate(weekOfFormatted)"
                    class="fixMe" outlined dense :color="appColor" readonly
                    v-bind="attrs" v-on="on"
                    @click:clear="changeWeekOfDate({ label: 'date', type: '', value: '' })"></v-text-field>
            </template>
            <v-date-picker :color="appColor" v-model="weekOf"
                @change="$event => { weekOfMenu = false; changeWeekOfDate({ label: 'date', type: '', value: $event }) }"></v-date-picker>
        </v-menu>
        <v-select style="max-width:200px" outlined v-model="selectedUser" :items="availableUsers" label="Reporter"></v-select><br>
        <v-btn depressed small :color="appColor" :disabled="selectedUser == 'all'" dark @click="downloadExpenseReport">Download Expense Report</v-btn>
      </div>
      <v-client-table
        :perPage="50"
        :data="filteredExpenses"
        :columns="columns"
        :options="{perPage:50}"
        >
        <span slot="h__name" >
            Expense Name
        </span>
        <span slot="h__date" >
            Expense Date
        </span>
        <span slot="h__userName" >
            Reporter
        </span>
        <div slot="department" slot-scope="{ row }">
          <div v-if="typeof row.department == 'object'"><span  v-for="(item, i) in row.department" class="dep-tag" :class="row.department[i].split(' ')[0]" :key="i">{{ item }}</span></div>
          <div v-else class="dep-tag" :class="row.department.split(' ')">{{ row.department}}</div>
        </div>
        <div slot="date" style="text-align:left; display:flex; align-items:center;" slot-scope="{ row }">
          {{ moment(row.date).utc().format('MM/DD/YYYY') }}
        </div>
        <div slot="userName" style="text-align:left; display:flex; align-items:center;" slot-scope="{ row }">
          {{ (row.userName) }}
        </div>
        <div slot="name" style="text-align:left; display:flex; align-items:center;" slot-scope="{ row }">
          {{ row.name }}&nbsp;&nbsp; <v-btn v-if="role == 'admin'" @click="deleteExpense(row._id)" dark :color="appColor" text icon small>  <i  class="fal fa-trash"></i></v-btn>
        </div>
        <div slot="amount" style="text-align:left; display:flex; align-items:center;" slot-scope="{ row }">
          ${{ row.amount }}
        </div>
        <div slot="receipt" style="text-align:left; display:flex; align-items:center;" slot-scope="{ row }">
         <v-btn @click="viewReceipt(row.receiptUrl)" dark :color="appColor" text small> <i class="fal fa-eye"></i></v-btn>
        </div>
        
        </v-client-table>

      <!-- <div @click="$router.push(`/forms/${form._id}`)" class="form-item" v-for="(form, i) in forms" :key="i">
        <i style="color:var(--app-color);margin-right:10px; font-size:18pt;" class="fad fa-file"></i>
        <div class="form-name">{{ form.name }}</div>
      </div> -->

     

    </div>
    
    
    
    
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Users",
  data() {
    return {
      moment,
      isLoaded: false,
      expenses:[],
      editUser:null,
      addUserLoading:false,
      errorMessage:'',
      form_frequency:'all',
      form_department:'all',
      createExpenseDialog:false,
      createExpenseLoading:false,
      expenseObj:{
        name:'',
        department:'all',
        for:'',
        vendor:'',
        amount:0
      },
      weekOf: moment().utc().startOf('week').format('YYYY-MM-DD'),
      weekOfFormatted: moment().utc().startOf('week').format('MM/DD/YYYY'),
      weekOfMenu:false,
      createExpenseErrorMessage:'',
      receiptImg: null,
      expenseDateMenu:false,
      expenseDateFormatted:'',
      tempReceiptAmt:0,
      previewReceipt:'',
      viewReceiptDialog:false,
      selectedUser:'all',
      availableUsers:[]
    };
  },
  async mounted() {
    
    this.getExpenses();
    await this.getUsers();

    
  },
  
  methods: {
    ...mapActions({
      facility_set:'facility_set'
    }),
    async getUsers(){
      let {data} = await axios.get(`/api/users/get_users`);
      this.availableUsers = [...data.items.map((item)=>{
        return {
          text: item.first_name + ' ' + item.last_name,
          value: item._id
        }
      }), {text:'All', value:'all'}];
    },
    async getUserName(id){
      let {data} = await axios.get(`/api/users/user/${id}`);
      return data.result.first_name + ' ' + data.result.last_name
    },
    viewReceipt(id){
      this.previewReceipt = id.replace('/uploads', '/api/static');
      this.viewReceiptDialog = true;
    },
    changeExpenseDate(item){
      if (item.type == '') {
          this['expenseDateFormatted'] = moment(item.value).utc().format('MM/DD/YYYY')
      } else {
          this['expenseDateFormatted'] = moment(item.value).utc().format('MM/DD/YYYY')
      }
      item.value = new Date(item.value);
    },
    changeWeekOfDate(item){
      if (item.type == '') {
          this['weekOfFormatted'] = moment(item.value).utc().startOf('week').format('MM/DD/YYYY')
      } else {
          this['weekOfFormatted'] = moment(item.value).utc().startOf('week').format('MM/DD/YYYY')
      }
      item.value = new Date(item.value);
    },
    formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    },
    parseDate(date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatMoney(number, decPlaces, decSep, thouSep) {
      decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSep = typeof decSep === "undefined" ? "." : decSep;
      thouSep = typeof thouSep === "undefined" ? "," : thouSep;
      var sign = number < 0 ? "-" : "";
      var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
      var j = (j = i.length) > 3 ? j % 3 : 0;

      return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    },
    uploadReceipt(e){
      this.receiptImg = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e)=>{
        this.$refs.receiptPreview.src = e.target.result;
      }
      reader.readAsDataURL(e.target.files[0]);
    },
    uploadReceiptClick(){
      this.$refs.fileInput.click();
    },
    async downloadExpenseReport(){
      function parseCurrency(currencyString) {
          // Remove non-numeric characters (e.g., currency symbols, commas)
          const cleanedString = currencyString.replace(/[^0-9.-]+/g, "");

          // Convert the cleaned string to a number
          const number = parseFloat(cleanedString);

          // Handle NaN (Not-a-Number)
          if (isNaN(number)) {
            return 0; // Or handle as appropriate for your application
          }

          return number;
      }
      
      let obj = {
        expenses: this.filteredExpenses,
        userId: this.selectedUser,
        date: this.weekOf,
        employeeName: this.availableUsers[this.availableUsers.findIndex((item)=>item.value == this.selectedUser)].text,
        hostname: window.location.hostname,
      }
      try{
      let {data} = await axios.post(`/api/expenses/create_report?facility=${this.facility}`, obj);
      let path = data.filepath.replace('./uploads', '/static');
      let urlPath = `https://${window.location.hostname}/api` + path;
      let name = path.split('/').pop();
      console.log(urlPath);
      var element = document.createElement('a');
      element.download =  name;
      element.href = urlPath;
      element.click();
      element.remove();


      }catch(e){
        console.log("SHTE")
      }
      
    },
    async createExpense(){
      this.createExpenseLoading = true;
      this.createExpenseErrorMessage = ''
      try{
        this.createExpenseLoading = true;
        let formData = new FormData();
        formData.append('receipt', this.receiptImg);
        formData.append('name', this.expenseObj.name);
        formData.append('date', this.expenseObj.date);
        formData.append('department', this.expenseObj.department);
        formData.append('amount', this.expenseObj.amount);
        formData.append('userId', this.authUser.id);
        formData.append('for', this.expenseObj.for);
        formData.append('vendor', this.expenseObj.vendor);  
        this.expenseObj = {
          name:'',
          department:'all',
          for:'',
          vendor:'',
          amount:0
        }
        this.receiptImg = null;
        let {data} = await axios.post(`/api/expenses/add?facility=${this.facility}`, formData);
        this.createExpenseLoading = false;
        this.createExpenseDialog = false;
        this.getExpenses();
      }catch(e){
        this.createExpenseLoading = false;
        this.createExpenseErrorMessage = 'There was a problem creating the expense';
      }
      
    },
    async deleteExpense(id){
      try{
        await axios.delete(`/api/expenses/${id}?facility=${this.facility}`);
        this.getExpenses();
      }catch(e){
        console.log(e);
      }
    },
    async getExpenses() {
      let {data} = await axios.get(`/api/expenses/all?facility=${this.facility}`);

      this.expenses = await Promise.all(data.items.map(async (item)=>{
        return {
          ...item,
          userName: await this.getUserName(item.userId)
        }
      }))
      this.isLoaded = true;
    },
  },
  computed:{
    ...mapGetters({
      authUser:'AUTH_USER',
      facility:'FACILITY'
    }),
    filteredExpenses(){
      let startWeek = moment(this.weekOf).utc().startOf('week').format('YYYY-MM-DD');
      let endWeek = moment(this.weekOf).utc().endOf('week').format('YYYY-MM-DD');
      return this.expenses.filter((item)=>{
        return moment(item.date).utc().isBetween(startWeek, endWeek);
      }).filter((item)=>{
        if(this.selectedUser == 'all') return true;
        return item.userId == this.selectedUser;
      })
      
    },
    role(){
      return this.authUser.role;
    },
    receiptAmt:{
      get(){
        return `$${this.expenseObj.amount}`;
      },
      set(v){
        this.expenseObj.amount = v;
      },
    },

    appColor(){
      if(this.facility == null) return '#4db7c9';
      return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
    },
    inventory(){
      return this.$route.path == '/inventory'
    },
    fac:{
      get(){
        return this.facility || '';
      },
      set(v){
        console.log(v);
        this.facility_set(v);
      }
    },
    page(){
      
      return this.$route.path;
    },
    
    columns(){
      return [
        'date',
        'name',
        'vendor',
        'for',
        'userName',
        'amount',
        'receipt',
        'department',
        
      ]
    }
  },
  watch:{
    form_frequency(v){
      this.isLoaded = false;
      this.forms = [];
      this.getExpenses();
    },
    form_department(v){
      this.isLoaded = false;
      this.forms = [];
      this.getExpenses();
    },
    page(v){
      this.isLoaded = false;
      this.forms = [];
      this.getExpenses();
    }
  }
 
};
</script>
<style scoped lang="scss">
@import "@/assets/scss.scss";
.f-name{
        font-size:15pt;
        
       font-weight: bold;
        
        i{
            color:var(--app-color);
            margin-right:10px;
        }
        
    }
.inner-stage{
  display:flex;
  justify-content: center;
}

.forms-container{
  display:flex;
  flex-direction: column;
  width:100%;
  padding:16px;
  gap:15px;
.form-item{
  cursor: pointer;
  max-width:100%;
  text-align: left;
  width:100%;
  height:100px;
  display:flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: $box-shadow;
  background:#fff;
  padding:25px;
  transition:all .3s ease;
  &:hover{
    box-shadow:rgba(23, 48, 73, 0.32) 0px 7px 12px;
  }
  i{
    margin-right:20px !important;
  }
  .form-name{
    font-size:15pt;
    font-weight: bold;
  }
}

}
.form-filters{
  margin:10px 0px;
  display:inline-flex;
  gap: 10px;
}
.dep-tag{
  background:#808080;
  color:#fff;
  padding:5px 10px;
  border-radius:100px;
  font-size:10pt;
  margin-right:5px;
  margin-bottom:5px;
  display:inline-block;
  @include media-query($small){
    font-size:6pt;
    padding:2px 5px;
  }
  &.rct{
    background:#5b1987;
  }
  &.medical{
    background:#b72132;
  }
  &.food{
    background:#3ba674;
  }
  &.all{
    background:#40b5cc;
  }


}
.freq-tag{
  font-size:.8em;
  color:darken(#eaeaea, 50%);
  background:#eaeaea;
  border-radius: 100px;
  display:inline-block;
  padding:5px 10px;
  margin-left:25px;
}
.tag-thing{
  width:150px;
  @include media-query($small){
    display:none;
  }
}
.receipt-preview{
  width:100%;
  height:200px;
  display:flex;
  justify-content: center;
  align-items: center;
  img{
    max-width:100%;
    max-height:100%;
  }
}
.fixMe {
    input {
        padding-left: 0 !important;
        font-size: 12pt !important;
    }
}
</style>