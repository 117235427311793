<template>
    <div class="admit-date" :class="type">
                            <div class="a-title">{{name}}</div>
                    <div class="a-date-range">
                            
                            
                            <div class="active-lead-item active-lead-2">
                                <div class="active-lead-item-title">Admit Date  <div @click="editingField != `${type}AdmitDate` ? $emit('activateEditingField',`${type}AdmitDate`) : $emit('updateEditingField', -1)" class="edit-value-icon" :class="{'active':editingField == `${type}AdmitDate`}">
                                    <i v-if="editingField == -1 || editingField != `${type}AdmitDate`" class="fal fa-pencil"></i>   
                                    <i v-if="editingField == `${type}AdmitDate`" class="fal fa-check"></i>   
                                </div></div>
                                <div class="active-lead-item-value select">
                                    <div  v-if="editingField == -1 || editingField != `${type}AdmitDate`">{{ admitDateFormatted }}</div>
                                    <div v-else>
                                        <v-menu
                                        v-model="admitDateMenu"
                                        :close-on-content-click="false"
                                        max-width="290"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="admitDateFormatted"
                                            style="padding-top:0 !important;"
                                            clearable
                                            
                                            class="fixMe"
                                            outlined
                                            dense
                                            :color="appColor"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="changeItem({label:`${type}AdmitDate`,type, value:''})"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        :color="appColor"
                                        
                                            v-model="admitDate"
                                            @change="$event => {admitDateMenu = false; changeItem({label:`${type}AdmitDate`,type, value:$event})}"
                                        ></v-date-picker>
                                        </v-menu>
                                        <div @click="$emit('updateEditingField', -1)" class="done-editing-icon"><i class="fal fa-check"></i></div>
                                    </div>
                                
                                </div>
                            </div>
                            <div class="active-lead-item active-lead-2">
                                <div class="active-lead-item-title">Discharge Date  <div @click="editingField != `${type}DischargeDate` ? $emit('activateEditingField',`${type}DischargeDate`) : $emit('updateEditingField', -1)" class="edit-value-icon" :class="{'active':editingField == `${type}DischargeDate`}">
                                    <i v-if="editingField == -1 || editingField != `${type}DischargeDate`" class="fal fa-pencil"></i>   
                                    <i v-if="editingField == `${type}DischargeDate`" class="fal fa-check"></i>   
                                </div></div>
                                <div class="active-lead-item-value select">
                                    <div  v-if="editingField == -1 || editingField != `${type}DischargeDate`">{{ dischargeDateFormatted }}</div> 
                                    <div v-else>
                                        <v-menu
                                        v-model="dischargeDateMenu"
                                        :close-on-content-click="false"
                                        max-width="290"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            @change="$event=>changeItem(`${type}DischargeDate`,type, $event)"
                                            v-model="dischargeDateFormatted"
                                            style="padding-top:0 !important;"
                                            clearable
                                            class="fixMe"
                                            outlined
                                            dense
                                            :color="appColor"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="changeItem({label:`${type}DischargeDate`,type, value:''})"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        :color="appColor"
                                        
                                        v-model="dischargeDate"
                                        @change="$event => {dischargeDateMenu = false; changeItem({label:`${type}DischargeDate`,type, value:$event})}"
                                        ></v-date-picker>
                                        </v-menu>
                                        <div @click="$emit('updateEditingField', -1)" class="done-editing-icon"><i class="fal fa-check"></i></div>
                                    </div>
                                
                                </div>
                            </div>
                    </div>
                    

                    
                    <div class="authorizations">
                        <div @click="authorizationsOpen = !authorizationsOpen" class="authorization-title">Authorizations <i :class="{'rotate':authorizationsOpen}" class="fal fa-chevron-down"></i></div>
                        <v-expand-transition>
                        <div v-if="authorizationsOpen" class="authorization-items">

                        
                        <div v-for="(item,i) in authorizations" :key="i" class="authorization-item">
                        <div class="authorization-name">{{ item.name }}</div>
                        </div>
                     </div>
                    </v-expand-transition>
                    </div>

</div>
</template>
<script>

import moment from 'moment-timezone';
export default {
    props:{
        type:{
            type:String,
            default:'total'
        },
        name:{
            type:String,
            default:''
        },
        startDate:{
            type:Date | String| null,
            default:undefined
        },
        endDate:{
            type:Date | String | null,
            default:undefined
        },
        editingField:{
            type:Number | String,
            default:''
        }

    },
    mounted(){
        this.admitDateFormatted = this.startDate != null && this.startDate != '' ? moment(this.startDate).format('MM/DD/YYYY') : '';
        this.dischargeDateFormatted = this.endDate != null && this.endDate != '' ? moment(this.endDate).format('MM/DD/YYYY') : ''
    },
    data(){
        return {
            moment,
            isLoading:false,
            expanded:false,
            admitDateFormatted:'',
            admitDateMenu:false,
            admitDate:null,
            dischargeDateFormatted:'',
            dischargeDateMenu:false,
            dischargeDate:null,
            authorizationsOpen:false
        }
    },
    computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        authorizations(){
            return [
                
            ]
        }
    },
    watch:{
        startDate(v){
            this.admitDateFormatted = v != null && v != '' ? moment(v).format('MM/DD/YYYY') : '';
        },
        endDate(v){
            this.dischargeDateFormatted = v != null && v != '' ? moment(v).format('MM/DD/YYYY') : ''
        }
    },
    methods:{
        setDischargeDate(date){
            this.dischargeDateFormatted = date != null && date != '' ? moment(date).format('MM/DD/YYYY') : '';
        },
        setAdmitDate(date){
            this.admitDateFormatted = date != null && date != '' ? moment(date).format('MM/DD/YYYY') : '';
        },
        async changeItem(item){
            if(item.label.indexOf('Admit') > -1){
                this.setAdmitDate(item.value)
            }
            if(item.label.indexOf('Discharge') > -1){
                this.setDischargeDate(item.value)
            }
            this.$emit('changeItem', item);
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate(date) {
            if (!date) return null
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    }
}
</script>
<style lang="scss" scoped>
.admit-date{
    .a-title{
        padding:5px 0px;
        border-bottom:solid 1px #ebebeb;
        font-weight: bold;
        
    }
    &.detox{
        border-left:solid 3px #308151;
        .a-title{
            color:#308151
        }
        padding-left:10px;
    }
    &.residential{
        border-left:solid 3px #308ee8;
        .a-title{
            color:#308ee8
        }
        padding-left:10px;
    }
    &.iop{
        border-left:solid 3px #9d54e7;
        .a-title{
            color:#9d54e7
        }
        padding-left:10px;
    }
    &.outpatient{
        border-left:solid 3px #cb50a0;
        .a-title{
            color:#cb50a0
        }
        padding-left:10px;
    }
    &.php{
        border-left:solid 3px #ff9900;
        .a-title{
            color:#ff9900
        }
        padding-left:10px;
    }
}
.active-lead-2{
    max-width:300px;
}
.a-date-range{
    display:flex;
    gap:15px;
}
.authorizations{
    background:#ebebeb;
    border-top:solid 1px #ebebeb;
    margin-top:15px;
    display:flex;
    flex-direction: column;
  
    .authorization-title{
        height:35px;
        background:#fff;
        display:inline-flex;
        align-items: center;
        padding-right:15px;
        color:#000;
        font-size: 8pt;
        font-weight: bold;
        cursor: pointer;
        transition: all .3s ease;
        padding:2px 10px;
        
        &:hover{
            background:#f8fafb;
            
        }
        i{
            transition:all .3s ease;
            margin-left:15px;
            transform-origin: center center;
            height:10px;
            width:10px;
            display:flex;
            justify-content: center;
            align-items: center;
            &.rotate{
                transform:rotate(180deg);
            }

        }
    }
    .authorization-items{
        display:flex;
        flex-direction: column;
        padding:0px 15px;
        gap:15px;
        .authorization-item{
            margin:10px 0px;
            background:#fff;
            border-radius: 5px;
            box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
            padding:15px;
        }
    }
    
}
</style>
