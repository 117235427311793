<template>
    <div class="admin-c">
        <v-dialog width="600" v-model="manageDepartmentOpen">
      <v-card>
          <v-card-title>
              Add Department
          </v-card-title>
          <v-card-text>
            <div > 
              <v-text-field small dense hide-details :color="appColor" label="Department Name" outlined v-model="editDepartment"></v-text-field>
            </div>
          </v-card-text>
          <v-card-text>
            <div style="text-align: left; color:#ff0000" v-if="errorMessage">{{ errorMessage }}</div>
          </v-card-text>
          <v-card-actions>
              <v-btn small @click="manageDepartmentOpen = false, addDepartment = false" text depressed>Cancel</v-btn>
              <v-btn small dark :color="appColor" depressed @click="submitAddDepartment(editDepartment)" :loading="savingDepartment" >Add</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="600" v-model="manageInsuranceOpen">
      <v-card>
          <v-card-title>
              Add Insurance
          </v-card-title>
          <v-card-text>
            <div > 
              <v-text-field small dense hide-details :color="appColor" label="Insurance Name" outlined v-model="editInsurance"></v-text-field>
            </div>
            <div>
              <color-picker :index="0" />
            </div>
          </v-card-text>
          <v-card-text>
            <div style="text-align: left; color:#ff0000" v-if="errorMessage">{{ errorMessage }}</div>
          </v-card-text>
          <v-card-actions>
              <v-btn small @click="manageInsuranceOpen = false, addInsurance = false" text depressed>Cancel</v-btn>
              <v-btn small dark :color="appColor" depressed @click="submitAddInsurance(editInsurance)" :loading="savingInsurance" >Add</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="isLoaded" class="admin-container">
      
      <div style="margin-bottom:15px;">
        <div class="f-name">Departments </div><br>
        <v-btn :color="appColor" small dark depressed @click="editDeparment = '', addDepartment = true; manageDepartmentOpen = true;">Add New Department</v-btn>
        
      </div>
    <v-client-table
            
            ref="my-table"
            
            :data="departments"
            :columns="['name','number_of_users']"
            :options="{perPage:50}"
            
            >
      <div slot="name" slot-scope="{ row }">
        <a @click="editDeparment = row.name, manageDepartmentOpen = true">{{ row.name }}</a>
      </div>
      <div slot="permissions" slot-scope="{ row }">
        <span style="margin-right:10px;" v-for="(item, i) in roleOptions" :key="i"><i v-if="row.permissions.includes(item.value)" style="margin-right:5px; color:var(--app-color)" class="fad fa-check-square"></i><i v-else style="margin-right:5px; color:#ff0000" class="fad fa-times-square"></i>{{ item.text }}</span>
      </div>
    </v-client-table>
    
    </div>

    <div v-if="isLoaded" class="admin-container">
      
      <div style="margin-bottom:15px;">
        <div class="f-name">Insurances </div><br>
        <v-btn :color="appColor" small dark depressed @click="editDeparment = '', addDepartment = true; manageDepartmentOpen = true;">Add New Insurance Company</v-btn>
        
      </div>
    <v-client-table
            
            ref="my-table"
            :data="insurances"
            :columns="['name', 'abbreviation', 'color']"
            :options="{perPage:50}"
            
            >
      <div slot="text" slot-scope="{ row }">
        <a @click="editInsurance = row.name, manageInsuranceOpen = true">{{ row.name }}</a>
      </div>
      <div slot="permissions" slot-scope="{ row }">
        <span style="margin-right:10px;" v-for="(item, i) in roleOptions" :key="i"><i v-if="row.permissions.includes(item.value)" style="margin-right:5px; color:var(--app-color)" class="fad fa-check-square"></i><i v-else style="margin-right:5px; color:#ff0000" class="fad fa-times-square"></i>{{ item.text }}</span>
      </div>
    </v-client-table>
    
    </div>
        


    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import axios from 'axios';
// import ColorPicker from "../components/colorPicker.vue";
export default {
    name:'admin',
    // components: { ColorPicker },
    data(){
        return {
            isLoaded:true,
            errorMessage:'',

            insurances:[],
            addInsurance:false,
            editInsurance:'', 
            manageInsuranceOpen:false,
            savingInsurance:false,
            
         
            departments:[],
            addDepartment:false,
            editDepartment:'',
            manageDepartmentOpen:false,
            savingDepartment:false

        }
    },
   async mounted(){
        this.departments = this.getDepartments();
        this.insurances = await this.getInsurances();
    },
    methods:{
        submitAddDepartment(){
            return null
        },
        async submitAddInsurance(){
          try{
            let post = {
              name
            }
            let {data} = await axios.post('/api/insurance/add', post);
            this.insurances =  this.getInsurances();
            
          }catch(e){
            console.log(e);
          }
        },
        getDepartments(){
            return [
                {
                    name:'Food Service',
                    number_of_users:3
                },
                {
                    name:'Medical',
                    number_of_users:4
                },
                {
                    name:'RCT',
                    number_of_users:6
                }
            ]
        },
        async getInsurances(){
          try{
                let { data } = await axios.get('/api/insurance/all');
                return data.items;
                // this.availableInsurances = [{text:'None', value:''}, ...data.items.map((item)=>{
                // return {
                //     value:item.name,
                //     text:this.getInsuranceLabel(item.name)
                // }
                // }), {text:'New Insurance', value:'new'}];
            }catch(e){
                console.log(e);
            }
        }
    },
    computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
        ...mapGetters({
            user:'AUTH_USER',
            facility:'FACILITY'
        })
    }
}
</script>
<style lang="scss">
@import "@/assets/scss.scss";
.admin-c{
    padding:45px;
    
   
    padding-top:85px;
    padding-left:265px;
    min-height:calc(100vh);
    width:100%;
    position:relative;
    text-align: left;
    @include media-query($small) {
        padding:0;
        padding-top:70px;
    }
}
.admin-box{
    overflow: hidden !important;
    border-radius: 10px;
    padding:25px;
    background:#fff;
    box-shadow: 0px 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.admin-container{
  display:flex;
  flex-direction: column;
  width:100%;
  padding:15px;
  text-align: left;
  .user-item{
    background: #fff;
    box-shadow: $box-shadow;
    border-radius: 5px;
    font-size:12pt;
    border:solid 1px #f1f1f1;
    margin-bottom:25px;
    padding:15px;
    display:grid;
    grid-template-columns:1fr;
    grid-gap:15px;
    flex-direction: column;
    max-width:500px;
    width:100%;
    text-align:left;
    position:relative;
    &:hover{
      .user-edit{
        opacity: 1;
      }
    }
    .user-edit{
      position:absolute;
      transition:all .3s ease;
      right:10px;
      top:10px;
      height:35px;
      width:35px;
      font-size:10pt;
      color:#808080;
      opacity: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      @include media-query($small) {
          opacity:1;
      }
      &:hover{
        transform:scale(1.1,1.1);
        background:#f1f1f1;
      }
      &:active{
        transform:none;
        transition:none;
      }
    }
    .user-name{
      font-weight: bold;
    }
    .user-role{
      background:#eaeaea;
      display:inline-block;
      border-radius: 100px;
      padding:4px 10px;
      font-size:9pt;
      text-transform: uppercase;
      letter-spacing: 2px;
      color:#808080;
    }
    .users-dets{
      font-size:.8em;
      color: #808080
    }
  }
  .add-user{
    display:flex;
    align-items: center;
    justify-content: center;
    transition:all .3s ease;
    &:hover{
      cursor: pointer;
      transform:scale(1.05,1.05);
      .add{
        i{
          color:var(--app-color);
        }
        color:var(--app-color)
      }
    }
    &:active{
      transition:none;
      transform: none;
    }
    
  }
}
.f-name{
        font-size:15pt;
        
       font-weight: bold;
        
        i{
            color:var(--app-color);
            margin-right:10px;
        }
        
    }
</style>